/* -------------------------------------------------------------------------- *\
  Fonts
\* -------------------------------------------------------------------------- */
@font-face {
  font-family: 'Objective';
  src: url("../../fonts/Objective-Medium.woff2") format("woff2"), url("../../fonts/Objective-Medium.otf") format("opentype");
  font-weight: 400; }

@font-face {
  font-family: 'Objective';
  src: url("../../fonts/Objective-Light.woff2") format("woff2"), url("../../fonts/Objective-Light.otf") format("opentype");
  font-weight: 100; }

@font-face {
  font-family: 'Objective';
  src: url("../../fonts/Objective-Bold.woff2") format("woff2"), url("../../fonts/Objective-Bold.otf") format("opentype");
  font-weight: 500; }

/* -------------------------------------------------------------------------- *\
  Reset
\* -------------------------------------------------------------------------- */

:root {
  --blue: #007bff;
  --indigo: #7A47C2;
  --purple: #7165e5;
  --dark-purple: #491a76;
  --darkest-purple: #270845;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #f5ca67;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #7165e5;
  --primary-darker: #5142df;
  --primary-darkest: #270845;
  --secondary: #f5ca67;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #f5ca67;
  --danger: #dc3545;
  --light: #fff;
  --dark: #491a76;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 810px;
  --breakpoint-lg: 1046px;
  --breakpoint-xl: 1210px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: 'Objective', sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #7165e5;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #3625d9;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #999;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

/* -------------------------------------------------------------------------- *\
  Bootstrap
\* -------------------------------------------------------------------------- */
    
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 2px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 2px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 810px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 1046px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1210px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 810px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1046px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1210px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.wrapper {
  width: 100%;
  padding: 0 20px; }

@media screen and (min-width: 1046px) {
  .wrapper {
    width: 900px;
    padding: 0;
    margin: 0 auto; } }

.mh-100vh {
  min-height: 100vh; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 2px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 4px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 4px solid #dee2e6; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 2px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 2px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 4px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #d7d4f8; }

.table-hover .table-primary:hover {
  background-color: #c3bef4; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #c3bef4; }

.table-primary-darker,
.table-primary-darker > th,
.table-primary-darker > td {
  background-color: #cecaf6; }

.table-hover .table-primary-darker:hover {
  background-color: #bab4f2; }
  .table-hover .table-primary-darker:hover > td,
  .table-hover .table-primary-darker:hover > th {
    background-color: #bab4f2; }

.table-primary-darkest,
.table-primary-darkest > th,
.table-primary-darkest > td {
  background-color: #c3bacb; }

.table-hover .table-primary-darkest:hover {
  background-color: #b6abc0; }
  .table-hover .table-primary-darkest:hover > td,
  .table-hover .table-primary-darkest:hover > th {
    background-color: #b6abc0; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #fcf0d4; }

.table-hover .table-secondary:hover {
  background-color: #fae8bc; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #fae8bc; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fcf0d4; }

.table-hover .table-warning:hover {
  background-color: #fae8bc; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fae8bc; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: white; }

.table-hover .table-light:hover {
  background-color: #f2f2f2; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f2f2f2; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #ccbfd9; }

.table-hover .table-dark:hover {
  background-color: #bfafd0; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #bfafd0; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 809.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 1045.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1209.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #d6d2f7;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(113, 101, 229, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.7rem + 4px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.form-control-fill {
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.form-control-card {
  background-color: #fff;
  border-color: #fff;
  padding: 1.2rem 1.2rem;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3); }
  .form-control-card:focus {
    color: #495057;
    background-color: #fff;
    border-color: #f5ca67;
    outline: 0;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3); }

select.form-control-card:not([size]):not([multiple]) {
  height: 4.2rem; }

.col-form-label {
  padding-top: calc(0.6rem + 2px);
  padding-bottom: calc(0.6rem + 2px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 2px);
  padding-bottom: calc(0.5rem + 2px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 2px);
  padding-bottom: calc(0.25rem + 2px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 2px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 4px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 4px); }

.form-group {
  margin-bottom: 1rem; }
  .form-group label {
    line-height: 1.5rem;
    font-size: 0.9rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #999; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 2px solid transparent;
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(113, 101, 229, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #7165e5;
  border-color: #7165e5; }
  .btn-primary:hover {
    color: #fff;
    background-color: #5344df;
    border-color: #4939de; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(113, 101, 229, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #7165e5;
    border-color: #7165e5; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #4939de;
    border-color: #3f2edc; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(113, 101, 229, 0.5); }

.btn-primary-darker {
  color: #fff;
  background-color: #5142df;
  border-color: #5142df; }
  .btn-primary-darker:hover {
    color: #fff;
    background-color: #3524d7;
    border-color: #3222cc; }
  .btn-primary-darker:focus, .btn-primary-darker.focus {
    box-shadow: 0 0 0 0.2rem rgba(81, 66, 223, 0.5); }
  .btn-primary-darker.disabled, .btn-primary-darker:disabled {
    color: #fff;
    background-color: #5142df;
    border-color: #5142df; }
  .btn-primary-darker:not(:disabled):not(.disabled):active, .btn-primary-darker:not(:disabled):not(.disabled).active,
  .show > .btn-primary-darker.dropdown-toggle {
    color: #fff;
    background-color: #3222cc;
    border-color: #3021c1; }
    .btn-primary-darker:not(:disabled):not(.disabled):active:focus, .btn-primary-darker:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary-darker.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(81, 66, 223, 0.5); }

.btn-primary-darkest {
  color: #fff;
  background-color: #270845;
  border-color: #270845; }
  .btn-primary-darkest:hover {
    color: #fff;
    background-color: #140423;
    border-color: #0d0317; }
  .btn-primary-darkest:focus, .btn-primary-darkest.focus {
    box-shadow: 0 0 0 0.2rem rgba(39, 8, 69, 0.5); }
  .btn-primary-darkest.disabled, .btn-primary-darkest:disabled {
    color: #fff;
    background-color: #270845;
    border-color: #270845; }
  .btn-primary-darkest:not(:disabled):not(.disabled):active, .btn-primary-darkest:not(:disabled):not(.disabled).active,
  .show > .btn-primary-darkest.dropdown-toggle {
    color: #fff;
    background-color: #0d0317;
    border-color: #07010c; }
    .btn-primary-darkest:not(:disabled):not(.disabled):active:focus, .btn-primary-darkest:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary-darkest.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(39, 8, 69, 0.5); }

.btn-secondary {
  color: #212529;
  background-color: #f5ca67;
  border-color: #f5ca67; }
  .btn-secondary:hover {
    color: #212529;
    background-color: #f3bd43;
    border-color: #f2b937; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 202, 103, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #f5ca67;
    border-color: #f5ca67; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #212529;
    background-color: #f2b937;
    border-color: #f1b52b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 202, 103, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #f5ca67;
  border-color: #f5ca67; }
  .btn-warning:hover {
    color: #212529;
    background-color: #f3bd43;
    border-color: #f2b937; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 202, 103, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #f5ca67;
    border-color: #f5ca67; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #f2b937;
    border-color: #f1b52b; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 202, 103, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-light {
  color: #212529;
  background-color: #fff;
  border-color: #fff; }
  .btn-light:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #491a76;
  border-color: #491a76; }
  .btn-dark:hover {
    color: #fff;
    background-color: #361357;
    border-color: #2f114c; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(73, 26, 118, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #491a76;
    border-color: #491a76; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #2f114c;
    border-color: #290e42; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(73, 26, 118, 0.5); }

.btn-light {
  color: #7165e5; }
  .btn-light:hover {
    color: #7165e5; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #7165e5; }

.btn-arrow-primary {
  color: #fff;
  background-color: #7165e5;
  border-color: #7165e5;
  padding-right: 3.84rem;
  position: relative; }
  .btn-arrow-primary:hover {
    color: #fff;
    background-color: #5344df;
    border-color: #4939de; }
  .btn-arrow-primary:focus, .btn-arrow-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(113, 101, 229, 0.5); }
  .btn-arrow-primary.disabled, .btn-arrow-primary:disabled {
    color: #fff;
    background-color: #7165e5;
    border-color: #7165e5; }
  .btn-arrow-primary:not(:disabled):not(.disabled):active, .btn-arrow-primary:not(:disabled):not(.disabled).active,
  .show > .btn-arrow-primary.dropdown-toggle {
    color: #fff;
    background-color: #4939de;
    border-color: #3f2edc; }
    .btn-arrow-primary:not(:disabled):not(.disabled):active:focus, .btn-arrow-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-arrow-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(113, 101, 229, 0.5); }
  .btn-arrow-primary::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-arrow-primary-darker {
  color: #fff;
  background-color: #5142df;
  border-color: #5142df;
  padding-right: 3.84rem;
  position: relative; }
  .btn-arrow-primary-darker:hover {
    color: #fff;
    background-color: #3524d7;
    border-color: #3222cc; }
  .btn-arrow-primary-darker:focus, .btn-arrow-primary-darker.focus {
    box-shadow: 0 0 0 0.2rem rgba(81, 66, 223, 0.5); }
  .btn-arrow-primary-darker.disabled, .btn-arrow-primary-darker:disabled {
    color: #fff;
    background-color: #5142df;
    border-color: #5142df; }
  .btn-arrow-primary-darker:not(:disabled):not(.disabled):active, .btn-arrow-primary-darker:not(:disabled):not(.disabled).active,
  .show > .btn-arrow-primary-darker.dropdown-toggle {
    color: #fff;
    background-color: #3222cc;
    border-color: #3021c1; }
    .btn-arrow-primary-darker:not(:disabled):not(.disabled):active:focus, .btn-arrow-primary-darker:not(:disabled):not(.disabled).active:focus,
    .show > .btn-arrow-primary-darker.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(81, 66, 223, 0.5); }
  .btn-arrow-primary-darker::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-arrow-primary-darkest {
  color: #fff;
  background-color: #270845;
  border-color: #270845;
  padding-right: 3.84rem;
  position: relative; }
  .btn-arrow-primary-darkest:hover {
    color: #fff;
    background-color: #140423;
    border-color: #0d0317; }
  .btn-arrow-primary-darkest:focus, .btn-arrow-primary-darkest.focus {
    box-shadow: 0 0 0 0.2rem rgba(39, 8, 69, 0.5); }
  .btn-arrow-primary-darkest.disabled, .btn-arrow-primary-darkest:disabled {
    color: #fff;
    background-color: #270845;
    border-color: #270845; }
  .btn-arrow-primary-darkest:not(:disabled):not(.disabled):active, .btn-arrow-primary-darkest:not(:disabled):not(.disabled).active,
  .show > .btn-arrow-primary-darkest.dropdown-toggle {
    color: #fff;
    background-color: #0d0317;
    border-color: #07010c; }
    .btn-arrow-primary-darkest:not(:disabled):not(.disabled):active:focus, .btn-arrow-primary-darkest:not(:disabled):not(.disabled).active:focus,
    .show > .btn-arrow-primary-darkest.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(39, 8, 69, 0.5); }
  .btn-arrow-primary-darkest::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-arrow-secondary {
  color: #212529;
  background-color: #f5ca67;
  border-color: #f5ca67;
  padding-right: 3.84rem;
  position: relative; }
  .btn-arrow-secondary:hover {
    color: #212529;
    background-color: #f3bd43;
    border-color: #f2b937; }
  .btn-arrow-secondary:focus, .btn-arrow-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 202, 103, 0.5); }
  .btn-arrow-secondary.disabled, .btn-arrow-secondary:disabled {
    color: #212529;
    background-color: #f5ca67;
    border-color: #f5ca67; }
  .btn-arrow-secondary:not(:disabled):not(.disabled):active, .btn-arrow-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-arrow-secondary.dropdown-toggle {
    color: #212529;
    background-color: #f2b937;
    border-color: #f1b52b; }
    .btn-arrow-secondary:not(:disabled):not(.disabled):active:focus, .btn-arrow-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-arrow-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 202, 103, 0.5); }
  .btn-arrow-secondary::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #212529;
    border-right: 2px solid #212529;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-arrow-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  padding-right: 3.84rem;
  position: relative; }
  .btn-arrow-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-arrow-success:focus, .btn-arrow-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-arrow-success.disabled, .btn-arrow-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-arrow-success:not(:disabled):not(.disabled):active, .btn-arrow-success:not(:disabled):not(.disabled).active,
  .show > .btn-arrow-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-arrow-success:not(:disabled):not(.disabled):active:focus, .btn-arrow-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-arrow-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-arrow-success::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-arrow-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  padding-right: 3.84rem;
  position: relative; }
  .btn-arrow-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-arrow-info:focus, .btn-arrow-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-arrow-info.disabled, .btn-arrow-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-arrow-info:not(:disabled):not(.disabled):active, .btn-arrow-info:not(:disabled):not(.disabled).active,
  .show > .btn-arrow-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-arrow-info:not(:disabled):not(.disabled):active:focus, .btn-arrow-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-arrow-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-arrow-info::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-arrow-warning {
  color: #212529;
  background-color: #f5ca67;
  border-color: #f5ca67;
  padding-right: 3.84rem;
  position: relative; }
  .btn-arrow-warning:hover {
    color: #212529;
    background-color: #f3bd43;
    border-color: #f2b937; }
  .btn-arrow-warning:focus, .btn-arrow-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 202, 103, 0.5); }
  .btn-arrow-warning.disabled, .btn-arrow-warning:disabled {
    color: #212529;
    background-color: #f5ca67;
    border-color: #f5ca67; }
  .btn-arrow-warning:not(:disabled):not(.disabled):active, .btn-arrow-warning:not(:disabled):not(.disabled).active,
  .show > .btn-arrow-warning.dropdown-toggle {
    color: #212529;
    background-color: #f2b937;
    border-color: #f1b52b; }
    .btn-arrow-warning:not(:disabled):not(.disabled):active:focus, .btn-arrow-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-arrow-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 202, 103, 0.5); }
  .btn-arrow-warning::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #212529;
    border-right: 2px solid #212529;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-arrow-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  padding-right: 3.84rem;
  position: relative; }
  .btn-arrow-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-arrow-danger:focus, .btn-arrow-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-arrow-danger.disabled, .btn-arrow-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-arrow-danger:not(:disabled):not(.disabled):active, .btn-arrow-danger:not(:disabled):not(.disabled).active,
  .show > .btn-arrow-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-arrow-danger:not(:disabled):not(.disabled):active:focus, .btn-arrow-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-arrow-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-arrow-danger::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-arrow-light {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
  padding-right: 3.84rem;
  position: relative; }
  .btn-arrow-light:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-arrow-light:focus, .btn-arrow-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-arrow-light.disabled, .btn-arrow-light:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-arrow-light:not(:disabled):not(.disabled):active, .btn-arrow-light:not(:disabled):not(.disabled).active,
  .show > .btn-arrow-light.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-arrow-light:not(:disabled):not(.disabled):active:focus, .btn-arrow-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-arrow-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-arrow-light::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #212529;
    border-right: 2px solid #212529;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-arrow-dark {
  color: #fff;
  background-color: #491a76;
  border-color: #491a76;
  padding-right: 3.84rem;
  position: relative; }
  .btn-arrow-dark:hover {
    color: #fff;
    background-color: #361357;
    border-color: #2f114c; }
  .btn-arrow-dark:focus, .btn-arrow-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(73, 26, 118, 0.5); }
  .btn-arrow-dark.disabled, .btn-arrow-dark:disabled {
    color: #fff;
    background-color: #491a76;
    border-color: #491a76; }
  .btn-arrow-dark:not(:disabled):not(.disabled):active, .btn-arrow-dark:not(:disabled):not(.disabled).active,
  .show > .btn-arrow-dark.dropdown-toggle {
    color: #fff;
    background-color: #2f114c;
    border-color: #290e42; }
    .btn-arrow-dark:not(:disabled):not(.disabled):active:focus, .btn-arrow-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-arrow-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(73, 26, 118, 0.5); }
  .btn-arrow-dark::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-outline-primary {
  color: #7165e5;
  background-color: transparent;
  background-image: none;
  border-color: #7165e5; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #7165e5;
    border-color: #7165e5; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(113, 101, 229, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #7165e5;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #7165e5;
    border-color: #7165e5; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(113, 101, 229, 0.5); }

.btn-outline-primary-darker {
  color: #5142df;
  background-color: transparent;
  background-image: none;
  border-color: #5142df; }
  .btn-outline-primary-darker:hover {
    color: #fff;
    background-color: #5142df;
    border-color: #5142df; }
  .btn-outline-primary-darker:focus, .btn-outline-primary-darker.focus {
    box-shadow: 0 0 0 0.2rem rgba(81, 66, 223, 0.5); }
  .btn-outline-primary-darker.disabled, .btn-outline-primary-darker:disabled {
    color: #5142df;
    background-color: transparent; }
  .btn-outline-primary-darker:not(:disabled):not(.disabled):active, .btn-outline-primary-darker:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-darker.dropdown-toggle {
    color: #fff;
    background-color: #5142df;
    border-color: #5142df; }
    .btn-outline-primary-darker:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-darker:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary-darker.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(81, 66, 223, 0.5); }

.btn-outline-primary-darkest {
  color: #270845;
  background-color: transparent;
  background-image: none;
  border-color: #270845; }
  .btn-outline-primary-darkest:hover {
    color: #fff;
    background-color: #270845;
    border-color: #270845; }
  .btn-outline-primary-darkest:focus, .btn-outline-primary-darkest.focus {
    box-shadow: 0 0 0 0.2rem rgba(39, 8, 69, 0.5); }
  .btn-outline-primary-darkest.disabled, .btn-outline-primary-darkest:disabled {
    color: #270845;
    background-color: transparent; }
  .btn-outline-primary-darkest:not(:disabled):not(.disabled):active, .btn-outline-primary-darkest:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-darkest.dropdown-toggle {
    color: #fff;
    background-color: #270845;
    border-color: #270845; }
    .btn-outline-primary-darkest:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-darkest:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary-darkest.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(39, 8, 69, 0.5); }

.btn-outline-secondary {
  color: #f5ca67;
  background-color: transparent;
  background-image: none;
  border-color: #f5ca67; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #f5ca67;
    border-color: #f5ca67; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 202, 103, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #f5ca67;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #f5ca67;
    border-color: #f5ca67; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 202, 103, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #f5ca67;
  background-color: transparent;
  background-image: none;
  border-color: #f5ca67; }
  .btn-outline-warning:hover {
    color: #fff;
    background-color: #f5ca67;
    border-color: #f5ca67; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 202, 103, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #f5ca67;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #f5ca67;
    border-color: #f5ca67; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 202, 103, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff; }
  .btn-outline-light:hover {
    color: #fff;
    background-color: #fff;
    border-color: #fff; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #fff;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #fff;
    background-color: #fff;
    border-color: #fff; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-dark {
  color: #491a76;
  background-color: transparent;
  background-image: none;
  border-color: #491a76; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #491a76;
    border-color: #491a76; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(73, 26, 118, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #491a76;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #491a76;
    border-color: #491a76; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(73, 26, 118, 0.5); }

.btn-outline-arrow-primary {
  color: #7165e5;
  background-color: transparent;
  background-image: none;
  border-color: #7165e5;
  padding-right: 3.84rem;
  position: relative; }
  .btn-outline-arrow-primary:hover {
    color: #fff;
    background-color: #7165e5;
    border-color: #7165e5; }
  .btn-outline-arrow-primary:focus, .btn-outline-arrow-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(113, 101, 229, 0.5); }
  .btn-outline-arrow-primary.disabled, .btn-outline-arrow-primary:disabled {
    color: #7165e5;
    background-color: transparent; }
  .btn-outline-arrow-primary:not(:disabled):not(.disabled):active, .btn-outline-arrow-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-arrow-primary.dropdown-toggle {
    color: #fff;
    background-color: #7165e5;
    border-color: #7165e5; }
    .btn-outline-arrow-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-arrow-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-arrow-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(113, 101, 229, 0.5); }
  .btn-outline-arrow-primary::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #7165e5;
    border-right: 2px solid #7165e5;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-outline-arrow-primary-darker {
  color: #5142df;
  background-color: transparent;
  background-image: none;
  border-color: #5142df;
  padding-right: 3.84rem;
  position: relative; }
  .btn-outline-arrow-primary-darker:hover {
    color: #fff;
    background-color: #5142df;
    border-color: #5142df; }
  .btn-outline-arrow-primary-darker:focus, .btn-outline-arrow-primary-darker.focus {
    box-shadow: 0 0 0 0.2rem rgba(81, 66, 223, 0.5); }
  .btn-outline-arrow-primary-darker.disabled, .btn-outline-arrow-primary-darker:disabled {
    color: #5142df;
    background-color: transparent; }
  .btn-outline-arrow-primary-darker:not(:disabled):not(.disabled):active, .btn-outline-arrow-primary-darker:not(:disabled):not(.disabled).active,
  .show > .btn-outline-arrow-primary-darker.dropdown-toggle {
    color: #fff;
    background-color: #5142df;
    border-color: #5142df; }
    .btn-outline-arrow-primary-darker:not(:disabled):not(.disabled):active:focus, .btn-outline-arrow-primary-darker:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-arrow-primary-darker.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(81, 66, 223, 0.5); }
  .btn-outline-arrow-primary-darker::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #5142df;
    border-right: 2px solid #5142df;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-outline-arrow-primary-darkest {
  color: #270845;
  background-color: transparent;
  background-image: none;
  border-color: #270845;
  padding-right: 3.84rem;
  position: relative; }
  .btn-outline-arrow-primary-darkest:hover {
    color: #fff;
    background-color: #270845;
    border-color: #270845; }
  .btn-outline-arrow-primary-darkest:focus, .btn-outline-arrow-primary-darkest.focus {
    box-shadow: 0 0 0 0.2rem rgba(39, 8, 69, 0.5); }
  .btn-outline-arrow-primary-darkest.disabled, .btn-outline-arrow-primary-darkest:disabled {
    color: #270845;
    background-color: transparent; }
  .btn-outline-arrow-primary-darkest:not(:disabled):not(.disabled):active, .btn-outline-arrow-primary-darkest:not(:disabled):not(.disabled).active,
  .show > .btn-outline-arrow-primary-darkest.dropdown-toggle {
    color: #fff;
    background-color: #270845;
    border-color: #270845; }
    .btn-outline-arrow-primary-darkest:not(:disabled):not(.disabled):active:focus, .btn-outline-arrow-primary-darkest:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-arrow-primary-darkest.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(39, 8, 69, 0.5); }
  .btn-outline-arrow-primary-darkest::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #270845;
    border-right: 2px solid #270845;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-outline-arrow-secondary {
  color: #f5ca67;
  background-color: transparent;
  background-image: none;
  border-color: #f5ca67;
  padding-right: 3.84rem;
  position: relative; }
  .btn-outline-arrow-secondary:hover {
    color: #fff;
    background-color: #f5ca67;
    border-color: #f5ca67; }
  .btn-outline-arrow-secondary:focus, .btn-outline-arrow-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 202, 103, 0.5); }
  .btn-outline-arrow-secondary.disabled, .btn-outline-arrow-secondary:disabled {
    color: #f5ca67;
    background-color: transparent; }
  .btn-outline-arrow-secondary:not(:disabled):not(.disabled):active, .btn-outline-arrow-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-arrow-secondary.dropdown-toggle {
    color: #fff;
    background-color: #f5ca67;
    border-color: #f5ca67; }
    .btn-outline-arrow-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-arrow-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-arrow-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 202, 103, 0.5); }
  .btn-outline-arrow-secondary::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #f5ca67;
    border-right: 2px solid #f5ca67;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-outline-arrow-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
  padding-right: 3.84rem;
  position: relative; }
  .btn-outline-arrow-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-arrow-success:focus, .btn-outline-arrow-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-arrow-success.disabled, .btn-outline-arrow-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-arrow-success:not(:disabled):not(.disabled):active, .btn-outline-arrow-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-arrow-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-arrow-success:not(:disabled):not(.disabled):active:focus, .btn-outline-arrow-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-arrow-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-arrow-success::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #28a745;
    border-right: 2px solid #28a745;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-outline-arrow-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
  padding-right: 3.84rem;
  position: relative; }
  .btn-outline-arrow-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-arrow-info:focus, .btn-outline-arrow-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-arrow-info.disabled, .btn-outline-arrow-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-arrow-info:not(:disabled):not(.disabled):active, .btn-outline-arrow-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-arrow-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-arrow-info:not(:disabled):not(.disabled):active:focus, .btn-outline-arrow-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-arrow-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-arrow-info::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #17a2b8;
    border-right: 2px solid #17a2b8;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-outline-arrow-warning {
  color: #f5ca67;
  background-color: transparent;
  background-image: none;
  border-color: #f5ca67;
  padding-right: 3.84rem;
  position: relative; }
  .btn-outline-arrow-warning:hover {
    color: #fff;
    background-color: #f5ca67;
    border-color: #f5ca67; }
  .btn-outline-arrow-warning:focus, .btn-outline-arrow-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 202, 103, 0.5); }
  .btn-outline-arrow-warning.disabled, .btn-outline-arrow-warning:disabled {
    color: #f5ca67;
    background-color: transparent; }
  .btn-outline-arrow-warning:not(:disabled):not(.disabled):active, .btn-outline-arrow-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-arrow-warning.dropdown-toggle {
    color: #fff;
    background-color: #f5ca67;
    border-color: #f5ca67; }
    .btn-outline-arrow-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-arrow-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-arrow-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 202, 103, 0.5); }
  .btn-outline-arrow-warning::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #f5ca67;
    border-right: 2px solid #f5ca67;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-outline-arrow-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
  padding-right: 3.84rem;
  position: relative; }
  .btn-outline-arrow-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-arrow-danger:focus, .btn-outline-arrow-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-arrow-danger.disabled, .btn-outline-arrow-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-arrow-danger:not(:disabled):not(.disabled):active, .btn-outline-arrow-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-arrow-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-arrow-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-arrow-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-arrow-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-arrow-danger::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #dc3545;
    border-right: 2px solid #dc3545;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-outline-arrow-light {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff;
  padding-right: 3.84rem;
  position: relative; }
  .btn-outline-arrow-light:hover {
    color: #fff;
    background-color: #fff;
    border-color: #fff; }
  .btn-outline-arrow-light:focus, .btn-outline-arrow-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-arrow-light.disabled, .btn-outline-arrow-light:disabled {
    color: #fff;
    background-color: transparent; }
  .btn-outline-arrow-light:not(:disabled):not(.disabled):active, .btn-outline-arrow-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-arrow-light.dropdown-toggle {
    color: #fff;
    background-color: #fff;
    border-color: #fff; }
    .btn-outline-arrow-light:not(:disabled):not(.disabled):active:focus, .btn-outline-arrow-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-arrow-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-arrow-light::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-outline-arrow-dark {
  color: #491a76;
  background-color: transparent;
  background-image: none;
  border-color: #491a76;
  padding-right: 3.84rem;
  position: relative; }
  .btn-outline-arrow-dark:hover {
    color: #fff;
    background-color: #491a76;
    border-color: #491a76; }
  .btn-outline-arrow-dark:focus, .btn-outline-arrow-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(73, 26, 118, 0.5); }
  .btn-outline-arrow-dark.disabled, .btn-outline-arrow-dark:disabled {
    color: #491a76;
    background-color: transparent; }
  .btn-outline-arrow-dark:not(:disabled):not(.disabled):active, .btn-outline-arrow-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-arrow-dark.dropdown-toggle {
    color: #fff;
    background-color: #491a76;
    border-color: #491a76; }
    .btn-outline-arrow-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-arrow-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-arrow-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(73, 26, 118, 0.5); }
  .btn-outline-arrow-dark::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #491a76;
    border-right: 2px solid #491a76;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-outline-primary-white {
  color: #7165e5;
  background-color: transparent;
  background-image: none;
  border-color: #7165e5;
  color: white; }
  .btn-outline-primary-white:hover {
    color: #fff;
    background-color: #7165e5;
    border-color: #7165e5; }
  .btn-outline-primary-white:focus, .btn-outline-primary-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(113, 101, 229, 0.5); }
  .btn-outline-primary-white.disabled, .btn-outline-primary-white:disabled {
    color: #7165e5;
    background-color: transparent; }
  .btn-outline-primary-white:not(:disabled):not(.disabled):active, .btn-outline-primary-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-white.dropdown-toggle {
    color: #fff;
    background-color: #7165e5;
    border-color: #7165e5; }
    .btn-outline-primary-white:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(113, 101, 229, 0.5); }

.btn-outline-primary-darker-white {
  color: #5142df;
  background-color: transparent;
  background-image: none;
  border-color: #5142df;
  color: white; }
  .btn-outline-primary-darker-white:hover {
    color: #fff;
    background-color: #5142df;
    border-color: #5142df; }
  .btn-outline-primary-darker-white:focus, .btn-outline-primary-darker-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(81, 66, 223, 0.5); }
  .btn-outline-primary-darker-white.disabled, .btn-outline-primary-darker-white:disabled {
    color: #5142df;
    background-color: transparent; }
  .btn-outline-primary-darker-white:not(:disabled):not(.disabled):active, .btn-outline-primary-darker-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-darker-white.dropdown-toggle {
    color: #fff;
    background-color: #5142df;
    border-color: #5142df; }
    .btn-outline-primary-darker-white:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-darker-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary-darker-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(81, 66, 223, 0.5); }

.btn-outline-primary-darkest-white {
  color: #270845;
  background-color: transparent;
  background-image: none;
  border-color: #270845;
  color: white; }
  .btn-outline-primary-darkest-white:hover {
    color: #fff;
    background-color: #270845;
    border-color: #270845; }
  .btn-outline-primary-darkest-white:focus, .btn-outline-primary-darkest-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(39, 8, 69, 0.5); }
  .btn-outline-primary-darkest-white.disabled, .btn-outline-primary-darkest-white:disabled {
    color: #270845;
    background-color: transparent; }
  .btn-outline-primary-darkest-white:not(:disabled):not(.disabled):active, .btn-outline-primary-darkest-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-darkest-white.dropdown-toggle {
    color: #fff;
    background-color: #270845;
    border-color: #270845; }
    .btn-outline-primary-darkest-white:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-darkest-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary-darkest-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(39, 8, 69, 0.5); }

.btn-outline-secondary-white {
  color: #f5ca67;
  background-color: transparent;
  background-image: none;
  border-color: #f5ca67;
  color: white; }
  .btn-outline-secondary-white:hover {
    color: #fff;
    background-color: #f5ca67;
    border-color: #f5ca67; }
  .btn-outline-secondary-white:focus, .btn-outline-secondary-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 202, 103, 0.5); }
  .btn-outline-secondary-white.disabled, .btn-outline-secondary-white:disabled {
    color: #f5ca67;
    background-color: transparent; }
  .btn-outline-secondary-white:not(:disabled):not(.disabled):active, .btn-outline-secondary-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary-white.dropdown-toggle {
    color: #fff;
    background-color: #f5ca67;
    border-color: #f5ca67; }
    .btn-outline-secondary-white:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 202, 103, 0.5); }

.btn-outline-success-white {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
  color: white; }
  .btn-outline-success-white:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success-white:focus, .btn-outline-success-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success-white.disabled, .btn-outline-success-white:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success-white:not(:disabled):not(.disabled):active, .btn-outline-success-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success-white.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success-white:not(:disabled):not(.disabled):active:focus, .btn-outline-success-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info-white {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
  color: white; }
  .btn-outline-info-white:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info-white:focus, .btn-outline-info-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info-white.disabled, .btn-outline-info-white:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info-white:not(:disabled):not(.disabled):active, .btn-outline-info-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info-white.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info-white:not(:disabled):not(.disabled):active:focus, .btn-outline-info-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning-white {
  color: #f5ca67;
  background-color: transparent;
  background-image: none;
  border-color: #f5ca67;
  color: white; }
  .btn-outline-warning-white:hover {
    color: #fff;
    background-color: #f5ca67;
    border-color: #f5ca67; }
  .btn-outline-warning-white:focus, .btn-outline-warning-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 202, 103, 0.5); }
  .btn-outline-warning-white.disabled, .btn-outline-warning-white:disabled {
    color: #f5ca67;
    background-color: transparent; }
  .btn-outline-warning-white:not(:disabled):not(.disabled):active, .btn-outline-warning-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning-white.dropdown-toggle {
    color: #fff;
    background-color: #f5ca67;
    border-color: #f5ca67; }
    .btn-outline-warning-white:not(:disabled):not(.disabled):active:focus, .btn-outline-warning-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 202, 103, 0.5); }

.btn-outline-danger-white {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
  color: white; }
  .btn-outline-danger-white:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger-white:focus, .btn-outline-danger-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger-white.disabled, .btn-outline-danger-white:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger-white:not(:disabled):not(.disabled):active, .btn-outline-danger-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger-white.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger-white:not(:disabled):not(.disabled):active:focus, .btn-outline-danger-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light-white {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff;
  color: white; }
  .btn-outline-light-white:hover {
    color: #fff;
    background-color: #fff;
    border-color: #fff; }
  .btn-outline-light-white:focus, .btn-outline-light-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-light-white.disabled, .btn-outline-light-white:disabled {
    color: #fff;
    background-color: transparent; }
  .btn-outline-light-white:not(:disabled):not(.disabled):active, .btn-outline-light-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light-white.dropdown-toggle {
    color: #fff;
    background-color: #fff;
    border-color: #fff; }
    .btn-outline-light-white:not(:disabled):not(.disabled):active:focus, .btn-outline-light-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-dark-white {
  color: #491a76;
  background-color: transparent;
  background-image: none;
  border-color: #491a76;
  color: white; }
  .btn-outline-dark-white:hover {
    color: #fff;
    background-color: #491a76;
    border-color: #491a76; }
  .btn-outline-dark-white:focus, .btn-outline-dark-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(73, 26, 118, 0.5); }
  .btn-outline-dark-white.disabled, .btn-outline-dark-white:disabled {
    color: #491a76;
    background-color: transparent; }
  .btn-outline-dark-white:not(:disabled):not(.disabled):active, .btn-outline-dark-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark-white.dropdown-toggle {
    color: #fff;
    background-color: #491a76;
    border-color: #491a76; }
    .btn-outline-dark-white:not(:disabled):not(.disabled):active:focus, .btn-outline-dark-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(73, 26, 118, 0.5); }

.btn-outline-arrow-primary-white {
  color: #7165e5;
  background-color: transparent;
  background-image: none;
  border-color: #7165e5;
  color: #fff;
  padding-right: 3.84rem;
  position: relative; }
  .btn-outline-arrow-primary-white:hover {
    color: #fff;
    background-color: #7165e5;
    border-color: #7165e5; }
  .btn-outline-arrow-primary-white:focus, .btn-outline-arrow-primary-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(113, 101, 229, 0.5); }
  .btn-outline-arrow-primary-white.disabled, .btn-outline-arrow-primary-white:disabled {
    color: #7165e5;
    background-color: transparent; }
  .btn-outline-arrow-primary-white:not(:disabled):not(.disabled):active, .btn-outline-arrow-primary-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-arrow-primary-white.dropdown-toggle {
    color: #fff;
    background-color: #7165e5;
    border-color: #7165e5; }
    .btn-outline-arrow-primary-white:not(:disabled):not(.disabled):active:focus, .btn-outline-arrow-primary-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-arrow-primary-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(113, 101, 229, 0.5); }
  .btn-outline-arrow-primary-white::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-outline-arrow-primary-darker-white {
  color: #5142df;
  background-color: transparent;
  background-image: none;
  border-color: #5142df;
  color: #fff;
  padding-right: 3.84rem;
  position: relative; }
  .btn-outline-arrow-primary-darker-white:hover {
    color: #fff;
    background-color: #5142df;
    border-color: #5142df; }
  .btn-outline-arrow-primary-darker-white:focus, .btn-outline-arrow-primary-darker-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(81, 66, 223, 0.5); }
  .btn-outline-arrow-primary-darker-white.disabled, .btn-outline-arrow-primary-darker-white:disabled {
    color: #5142df;
    background-color: transparent; }
  .btn-outline-arrow-primary-darker-white:not(:disabled):not(.disabled):active, .btn-outline-arrow-primary-darker-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-arrow-primary-darker-white.dropdown-toggle {
    color: #fff;
    background-color: #5142df;
    border-color: #5142df; }
    .btn-outline-arrow-primary-darker-white:not(:disabled):not(.disabled):active:focus, .btn-outline-arrow-primary-darker-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-arrow-primary-darker-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(81, 66, 223, 0.5); }
  .btn-outline-arrow-primary-darker-white::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-outline-arrow-primary-darkest-white {
  color: #270845;
  background-color: transparent;
  background-image: none;
  border-color: #270845;
  color: #fff;
  padding-right: 3.84rem;
  position: relative; }
  .btn-outline-arrow-primary-darkest-white:hover {
    color: #fff;
    background-color: #270845;
    border-color: #270845; }
  .btn-outline-arrow-primary-darkest-white:focus, .btn-outline-arrow-primary-darkest-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(39, 8, 69, 0.5); }
  .btn-outline-arrow-primary-darkest-white.disabled, .btn-outline-arrow-primary-darkest-white:disabled {
    color: #270845;
    background-color: transparent; }
  .btn-outline-arrow-primary-darkest-white:not(:disabled):not(.disabled):active, .btn-outline-arrow-primary-darkest-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-arrow-primary-darkest-white.dropdown-toggle {
    color: #fff;
    background-color: #270845;
    border-color: #270845; }
    .btn-outline-arrow-primary-darkest-white:not(:disabled):not(.disabled):active:focus, .btn-outline-arrow-primary-darkest-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-arrow-primary-darkest-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(39, 8, 69, 0.5); }
  .btn-outline-arrow-primary-darkest-white::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-outline-arrow-secondary-white {
  color: #f5ca67;
  background-color: transparent;
  background-image: none;
  border-color: #f5ca67;
  color: #fff;
  padding-right: 3.84rem;
  position: relative; }
  .btn-outline-arrow-secondary-white:hover {
    color: #fff;
    background-color: #f5ca67;
    border-color: #f5ca67; }
  .btn-outline-arrow-secondary-white:focus, .btn-outline-arrow-secondary-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 202, 103, 0.5); }
  .btn-outline-arrow-secondary-white.disabled, .btn-outline-arrow-secondary-white:disabled {
    color: #f5ca67;
    background-color: transparent; }
  .btn-outline-arrow-secondary-white:not(:disabled):not(.disabled):active, .btn-outline-arrow-secondary-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-arrow-secondary-white.dropdown-toggle {
    color: #fff;
    background-color: #f5ca67;
    border-color: #f5ca67; }
    .btn-outline-arrow-secondary-white:not(:disabled):not(.disabled):active:focus, .btn-outline-arrow-secondary-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-arrow-secondary-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 202, 103, 0.5); }
  .btn-outline-arrow-secondary-white::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-outline-arrow-success-white {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
  color: #fff;
  padding-right: 3.84rem;
  position: relative; }
  .btn-outline-arrow-success-white:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-arrow-success-white:focus, .btn-outline-arrow-success-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-arrow-success-white.disabled, .btn-outline-arrow-success-white:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-arrow-success-white:not(:disabled):not(.disabled):active, .btn-outline-arrow-success-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-arrow-success-white.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-arrow-success-white:not(:disabled):not(.disabled):active:focus, .btn-outline-arrow-success-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-arrow-success-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-arrow-success-white::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-outline-arrow-info-white {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
  color: #fff;
  padding-right: 3.84rem;
  position: relative; }
  .btn-outline-arrow-info-white:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-arrow-info-white:focus, .btn-outline-arrow-info-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-arrow-info-white.disabled, .btn-outline-arrow-info-white:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-arrow-info-white:not(:disabled):not(.disabled):active, .btn-outline-arrow-info-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-arrow-info-white.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-arrow-info-white:not(:disabled):not(.disabled):active:focus, .btn-outline-arrow-info-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-arrow-info-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-arrow-info-white::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-outline-arrow-warning-white {
  color: #f5ca67;
  background-color: transparent;
  background-image: none;
  border-color: #f5ca67;
  color: #fff;
  padding-right: 3.84rem;
  position: relative; }
  .btn-outline-arrow-warning-white:hover {
    color: #fff;
    background-color: #f5ca67;
    border-color: #f5ca67; }
  .btn-outline-arrow-warning-white:focus, .btn-outline-arrow-warning-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 202, 103, 0.5); }
  .btn-outline-arrow-warning-white.disabled, .btn-outline-arrow-warning-white:disabled {
    color: #f5ca67;
    background-color: transparent; }
  .btn-outline-arrow-warning-white:not(:disabled):not(.disabled):active, .btn-outline-arrow-warning-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-arrow-warning-white.dropdown-toggle {
    color: #fff;
    background-color: #f5ca67;
    border-color: #f5ca67; }
    .btn-outline-arrow-warning-white:not(:disabled):not(.disabled):active:focus, .btn-outline-arrow-warning-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-arrow-warning-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 202, 103, 0.5); }
  .btn-outline-arrow-warning-white::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-outline-arrow-danger-white {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
  color: #fff;
  padding-right: 3.84rem;
  position: relative; }
  .btn-outline-arrow-danger-white:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-arrow-danger-white:focus, .btn-outline-arrow-danger-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-arrow-danger-white.disabled, .btn-outline-arrow-danger-white:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-arrow-danger-white:not(:disabled):not(.disabled):active, .btn-outline-arrow-danger-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-arrow-danger-white.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-arrow-danger-white:not(:disabled):not(.disabled):active:focus, .btn-outline-arrow-danger-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-arrow-danger-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-arrow-danger-white::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-outline-arrow-light-white {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff;
  color: #fff;
  padding-right: 3.84rem;
  position: relative; }
  .btn-outline-arrow-light-white:hover {
    color: #fff;
    background-color: #fff;
    border-color: #fff; }
  .btn-outline-arrow-light-white:focus, .btn-outline-arrow-light-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-arrow-light-white.disabled, .btn-outline-arrow-light-white:disabled {
    color: #fff;
    background-color: transparent; }
  .btn-outline-arrow-light-white:not(:disabled):not(.disabled):active, .btn-outline-arrow-light-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-arrow-light-white.dropdown-toggle {
    color: #fff;
    background-color: #fff;
    border-color: #fff; }
    .btn-outline-arrow-light-white:not(:disabled):not(.disabled):active:focus, .btn-outline-arrow-light-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-arrow-light-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-arrow-light-white::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-outline-arrow-dark-white {
  color: #491a76;
  background-color: transparent;
  background-image: none;
  border-color: #491a76;
  color: #fff;
  padding-right: 3.84rem;
  position: relative; }
  .btn-outline-arrow-dark-white:hover {
    color: #fff;
    background-color: #491a76;
    border-color: #491a76; }
  .btn-outline-arrow-dark-white:focus, .btn-outline-arrow-dark-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(73, 26, 118, 0.5); }
  .btn-outline-arrow-dark-white.disabled, .btn-outline-arrow-dark-white:disabled {
    color: #491a76;
    background-color: transparent; }
  .btn-outline-arrow-dark-white:not(:disabled):not(.disabled):active, .btn-outline-arrow-dark-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-arrow-dark-white.dropdown-toggle {
    color: #fff;
    background-color: #491a76;
    border-color: #491a76; }
    .btn-outline-arrow-dark-white:not(:disabled):not(.disabled):active:focus, .btn-outline-arrow-dark-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-arrow-dark-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(73, 26, 118, 0.5); }
  .btn-outline-arrow-dark-white::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-clear-primary {
  color: #7165e5;
  background-color: transparent;
  background-image: none; }
  .btn-clear-primary:hover {
    color: #5344df; }
  .btn-clear-primary:focus, .btn-clear-primary.focus {
    box-shadow: 0 1.4rem 0 -1.2em rgba(113, 101, 229, 0.5); }
  .btn-clear-primary.disabled, .btn-clear-primary:disabled {
    color: #7165e5;
    background-color: transparent; }
  .btn-clear-primary:not(:disabled):not(.disabled):active, .btn-clear-primary:not(:disabled):not(.disabled).active,
  .show > .btn-clear-primary.dropdown-toggle {
    color: #7165e5; }
    .btn-clear-primary:not(:disabled):not(.disabled):active:focus, .btn-clear-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-clear-primary.dropdown-toggle:focus {
      box-shadow: 0 1.4rem 0 -1.2rem rgba(113, 101, 229, 0.5); }

.btn-clear-primary-darker {
  color: #5142df;
  background-color: transparent;
  background-image: none; }
  .btn-clear-primary-darker:hover {
    color: #3524d7; }
  .btn-clear-primary-darker:focus, .btn-clear-primary-darker.focus {
    box-shadow: 0 1.4rem 0 -1.2em rgba(81, 66, 223, 0.5); }
  .btn-clear-primary-darker.disabled, .btn-clear-primary-darker:disabled {
    color: #5142df;
    background-color: transparent; }
  .btn-clear-primary-darker:not(:disabled):not(.disabled):active, .btn-clear-primary-darker:not(:disabled):not(.disabled).active,
  .show > .btn-clear-primary-darker.dropdown-toggle {
    color: #5142df; }
    .btn-clear-primary-darker:not(:disabled):not(.disabled):active:focus, .btn-clear-primary-darker:not(:disabled):not(.disabled).active:focus,
    .show > .btn-clear-primary-darker.dropdown-toggle:focus {
      box-shadow: 0 1.4rem 0 -1.2rem rgba(81, 66, 223, 0.5); }

.btn-clear-primary-darkest {
  color: #270845;
  background-color: transparent;
  background-image: none; }
  .btn-clear-primary-darkest:hover {
    color: #140423; }
  .btn-clear-primary-darkest:focus, .btn-clear-primary-darkest.focus {
    box-shadow: 0 1.4rem 0 -1.2em rgba(39, 8, 69, 0.5); }
  .btn-clear-primary-darkest.disabled, .btn-clear-primary-darkest:disabled {
    color: #270845;
    background-color: transparent; }
  .btn-clear-primary-darkest:not(:disabled):not(.disabled):active, .btn-clear-primary-darkest:not(:disabled):not(.disabled).active,
  .show > .btn-clear-primary-darkest.dropdown-toggle {
    color: #270845; }
    .btn-clear-primary-darkest:not(:disabled):not(.disabled):active:focus, .btn-clear-primary-darkest:not(:disabled):not(.disabled).active:focus,
    .show > .btn-clear-primary-darkest.dropdown-toggle:focus {
      box-shadow: 0 1.4rem 0 -1.2rem rgba(39, 8, 69, 0.5); }

.btn-clear-secondary {
  color: #f5ca67;
  background-color: transparent;
  background-image: none; }
  .btn-clear-secondary:hover {
    color: #f3bd43; }
  .btn-clear-secondary:focus, .btn-clear-secondary.focus {
    box-shadow: 0 1.4rem 0 -1.2em rgba(245, 202, 103, 0.5); }
  .btn-clear-secondary.disabled, .btn-clear-secondary:disabled {
    color: #f5ca67;
    background-color: transparent; }
  .btn-clear-secondary:not(:disabled):not(.disabled):active, .btn-clear-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-clear-secondary.dropdown-toggle {
    color: #f5ca67; }
    .btn-clear-secondary:not(:disabled):not(.disabled):active:focus, .btn-clear-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-clear-secondary.dropdown-toggle:focus {
      box-shadow: 0 1.4rem 0 -1.2rem rgba(245, 202, 103, 0.5); }

.btn-clear-success {
  color: #28a745;
  background-color: transparent;
  background-image: none; }
  .btn-clear-success:hover {
    color: #218838; }
  .btn-clear-success:focus, .btn-clear-success.focus {
    box-shadow: 0 1.4rem 0 -1.2em rgba(40, 167, 69, 0.5); }
  .btn-clear-success.disabled, .btn-clear-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-clear-success:not(:disabled):not(.disabled):active, .btn-clear-success:not(:disabled):not(.disabled).active,
  .show > .btn-clear-success.dropdown-toggle {
    color: #28a745; }
    .btn-clear-success:not(:disabled):not(.disabled):active:focus, .btn-clear-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-clear-success.dropdown-toggle:focus {
      box-shadow: 0 1.4rem 0 -1.2rem rgba(40, 167, 69, 0.5); }

.btn-clear-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none; }
  .btn-clear-info:hover {
    color: #138496; }
  .btn-clear-info:focus, .btn-clear-info.focus {
    box-shadow: 0 1.4rem 0 -1.2em rgba(23, 162, 184, 0.5); }
  .btn-clear-info.disabled, .btn-clear-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-clear-info:not(:disabled):not(.disabled):active, .btn-clear-info:not(:disabled):not(.disabled).active,
  .show > .btn-clear-info.dropdown-toggle {
    color: #17a2b8; }
    .btn-clear-info:not(:disabled):not(.disabled):active:focus, .btn-clear-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-clear-info.dropdown-toggle:focus {
      box-shadow: 0 1.4rem 0 -1.2rem rgba(23, 162, 184, 0.5); }

.btn-clear-warning {
  color: #f5ca67;
  background-color: transparent;
  background-image: none; }
  .btn-clear-warning:hover {
    color: #f3bd43; }
  .btn-clear-warning:focus, .btn-clear-warning.focus {
    box-shadow: 0 1.4rem 0 -1.2em rgba(245, 202, 103, 0.5); }
  .btn-clear-warning.disabled, .btn-clear-warning:disabled {
    color: #f5ca67;
    background-color: transparent; }
  .btn-clear-warning:not(:disabled):not(.disabled):active, .btn-clear-warning:not(:disabled):not(.disabled).active,
  .show > .btn-clear-warning.dropdown-toggle {
    color: #f5ca67; }
    .btn-clear-warning:not(:disabled):not(.disabled):active:focus, .btn-clear-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-clear-warning.dropdown-toggle:focus {
      box-shadow: 0 1.4rem 0 -1.2rem rgba(245, 202, 103, 0.5); }

.btn-clear-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none; }
  .btn-clear-danger:hover {
    color: #c82333; }
  .btn-clear-danger:focus, .btn-clear-danger.focus {
    box-shadow: 0 1.4rem 0 -1.2em rgba(220, 53, 69, 0.5); }
  .btn-clear-danger.disabled, .btn-clear-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-clear-danger:not(:disabled):not(.disabled):active, .btn-clear-danger:not(:disabled):not(.disabled).active,
  .show > .btn-clear-danger.dropdown-toggle {
    color: #dc3545; }
    .btn-clear-danger:not(:disabled):not(.disabled):active:focus, .btn-clear-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-clear-danger.dropdown-toggle:focus {
      box-shadow: 0 1.4rem 0 -1.2rem rgba(220, 53, 69, 0.5); }

.btn-clear-light {
  color: #fff;
  background-color: transparent;
  background-image: none; }
  .btn-clear-light:hover {
    color: #ececec; }
  .btn-clear-light:focus, .btn-clear-light.focus {
    box-shadow: 0 1.4rem 0 -1.2em rgba(255, 255, 255, 0.5); }
  .btn-clear-light.disabled, .btn-clear-light:disabled {
    color: #fff;
    background-color: transparent; }
  .btn-clear-light:not(:disabled):not(.disabled):active, .btn-clear-light:not(:disabled):not(.disabled).active,
  .show > .btn-clear-light.dropdown-toggle {
    color: #fff; }
    .btn-clear-light:not(:disabled):not(.disabled):active:focus, .btn-clear-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-clear-light.dropdown-toggle:focus {
      box-shadow: 0 1.4rem 0 -1.2rem rgba(255, 255, 255, 0.5); }

.btn-clear-dark {
  color: #491a76;
  background-color: transparent;
  background-image: none; }
  .btn-clear-dark:hover {
    color: #361357; }
  .btn-clear-dark:focus, .btn-clear-dark.focus {
    box-shadow: 0 1.4rem 0 -1.2em rgba(73, 26, 118, 0.5); }
  .btn-clear-dark.disabled, .btn-clear-dark:disabled {
    color: #491a76;
    background-color: transparent; }
  .btn-clear-dark:not(:disabled):not(.disabled):active, .btn-clear-dark:not(:disabled):not(.disabled).active,
  .show > .btn-clear-dark.dropdown-toggle {
    color: #491a76; }
    .btn-clear-dark:not(:disabled):not(.disabled):active:focus, .btn-clear-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-clear-dark.dropdown-toggle:focus {
      box-shadow: 0 1.4rem 0 -1.2rem rgba(73, 26, 118, 0.5); }

.btn-clear-arrow-primary {
  color: #7165e5;
  background-color: transparent;
  background-image: none;
  padding-right: 3.84rem;
  position: relative; }
  .btn-clear-arrow-primary:hover {
    color: #5344df; }
  .btn-clear-arrow-primary:focus, .btn-clear-arrow-primary.focus {
    box-shadow: 0 1.4rem 0 -1.2em rgba(113, 101, 229, 0.5); }
  .btn-clear-arrow-primary.disabled, .btn-clear-arrow-primary:disabled {
    color: #7165e5;
    background-color: transparent; }
  .btn-clear-arrow-primary:not(:disabled):not(.disabled):active, .btn-clear-arrow-primary:not(:disabled):not(.disabled).active,
  .show > .btn-clear-arrow-primary.dropdown-toggle {
    color: #7165e5; }
    .btn-clear-arrow-primary:not(:disabled):not(.disabled):active:focus, .btn-clear-arrow-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-clear-arrow-primary.dropdown-toggle:focus {
      box-shadow: 0 1.4rem 0 -1.2rem rgba(113, 101, 229, 0.5); }
  .btn-clear-arrow-primary::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #7165e5;
    border-right: 2px solid #7165e5;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-clear-arrow-primary-darker {
  color: #5142df;
  background-color: transparent;
  background-image: none;
  padding-right: 3.84rem;
  position: relative; }
  .btn-clear-arrow-primary-darker:hover {
    color: #3524d7; }
  .btn-clear-arrow-primary-darker:focus, .btn-clear-arrow-primary-darker.focus {
    box-shadow: 0 1.4rem 0 -1.2em rgba(81, 66, 223, 0.5); }
  .btn-clear-arrow-primary-darker.disabled, .btn-clear-arrow-primary-darker:disabled {
    color: #5142df;
    background-color: transparent; }
  .btn-clear-arrow-primary-darker:not(:disabled):not(.disabled):active, .btn-clear-arrow-primary-darker:not(:disabled):not(.disabled).active,
  .show > .btn-clear-arrow-primary-darker.dropdown-toggle {
    color: #5142df; }
    .btn-clear-arrow-primary-darker:not(:disabled):not(.disabled):active:focus, .btn-clear-arrow-primary-darker:not(:disabled):not(.disabled).active:focus,
    .show > .btn-clear-arrow-primary-darker.dropdown-toggle:focus {
      box-shadow: 0 1.4rem 0 -1.2rem rgba(81, 66, 223, 0.5); }
  .btn-clear-arrow-primary-darker::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #5142df;
    border-right: 2px solid #5142df;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-clear-arrow-primary-darkest {
  color: #270845;
  background-color: transparent;
  background-image: none;
  padding-right: 3.84rem;
  position: relative; }
  .btn-clear-arrow-primary-darkest:hover {
    color: #140423; }
  .btn-clear-arrow-primary-darkest:focus, .btn-clear-arrow-primary-darkest.focus {
    box-shadow: 0 1.4rem 0 -1.2em rgba(39, 8, 69, 0.5); }
  .btn-clear-arrow-primary-darkest.disabled, .btn-clear-arrow-primary-darkest:disabled {
    color: #270845;
    background-color: transparent; }
  .btn-clear-arrow-primary-darkest:not(:disabled):not(.disabled):active, .btn-clear-arrow-primary-darkest:not(:disabled):not(.disabled).active,
  .show > .btn-clear-arrow-primary-darkest.dropdown-toggle {
    color: #270845; }
    .btn-clear-arrow-primary-darkest:not(:disabled):not(.disabled):active:focus, .btn-clear-arrow-primary-darkest:not(:disabled):not(.disabled).active:focus,
    .show > .btn-clear-arrow-primary-darkest.dropdown-toggle:focus {
      box-shadow: 0 1.4rem 0 -1.2rem rgba(39, 8, 69, 0.5); }
  .btn-clear-arrow-primary-darkest::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #270845;
    border-right: 2px solid #270845;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-clear-arrow-secondary {
  color: #f5ca67;
  background-color: transparent;
  background-image: none;
  padding-right: 3.84rem;
  position: relative; }
  .btn-clear-arrow-secondary:hover {
    color: #f3bd43; }
  .btn-clear-arrow-secondary:focus, .btn-clear-arrow-secondary.focus {
    box-shadow: 0 1.4rem 0 -1.2em rgba(245, 202, 103, 0.5); }
  .btn-clear-arrow-secondary.disabled, .btn-clear-arrow-secondary:disabled {
    color: #f5ca67;
    background-color: transparent; }
  .btn-clear-arrow-secondary:not(:disabled):not(.disabled):active, .btn-clear-arrow-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-clear-arrow-secondary.dropdown-toggle {
    color: #f5ca67; }
    .btn-clear-arrow-secondary:not(:disabled):not(.disabled):active:focus, .btn-clear-arrow-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-clear-arrow-secondary.dropdown-toggle:focus {
      box-shadow: 0 1.4rem 0 -1.2rem rgba(245, 202, 103, 0.5); }
  .btn-clear-arrow-secondary::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #f5ca67;
    border-right: 2px solid #f5ca67;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-clear-arrow-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  padding-right: 3.84rem;
  position: relative; }
  .btn-clear-arrow-success:hover {
    color: #218838; }
  .btn-clear-arrow-success:focus, .btn-clear-arrow-success.focus {
    box-shadow: 0 1.4rem 0 -1.2em rgba(40, 167, 69, 0.5); }
  .btn-clear-arrow-success.disabled, .btn-clear-arrow-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-clear-arrow-success:not(:disabled):not(.disabled):active, .btn-clear-arrow-success:not(:disabled):not(.disabled).active,
  .show > .btn-clear-arrow-success.dropdown-toggle {
    color: #28a745; }
    .btn-clear-arrow-success:not(:disabled):not(.disabled):active:focus, .btn-clear-arrow-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-clear-arrow-success.dropdown-toggle:focus {
      box-shadow: 0 1.4rem 0 -1.2rem rgba(40, 167, 69, 0.5); }
  .btn-clear-arrow-success::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #28a745;
    border-right: 2px solid #28a745;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-clear-arrow-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  padding-right: 3.84rem;
  position: relative; }
  .btn-clear-arrow-info:hover {
    color: #138496; }
  .btn-clear-arrow-info:focus, .btn-clear-arrow-info.focus {
    box-shadow: 0 1.4rem 0 -1.2em rgba(23, 162, 184, 0.5); }
  .btn-clear-arrow-info.disabled, .btn-clear-arrow-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-clear-arrow-info:not(:disabled):not(.disabled):active, .btn-clear-arrow-info:not(:disabled):not(.disabled).active,
  .show > .btn-clear-arrow-info.dropdown-toggle {
    color: #17a2b8; }
    .btn-clear-arrow-info:not(:disabled):not(.disabled):active:focus, .btn-clear-arrow-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-clear-arrow-info.dropdown-toggle:focus {
      box-shadow: 0 1.4rem 0 -1.2rem rgba(23, 162, 184, 0.5); }
  .btn-clear-arrow-info::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #17a2b8;
    border-right: 2px solid #17a2b8;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-clear-arrow-warning {
  color: #f5ca67;
  background-color: transparent;
  background-image: none;
  padding-right: 3.84rem;
  position: relative; }
  .btn-clear-arrow-warning:hover {
    color: #f3bd43; }
  .btn-clear-arrow-warning:focus, .btn-clear-arrow-warning.focus {
    box-shadow: 0 1.4rem 0 -1.2em rgba(245, 202, 103, 0.5); }
  .btn-clear-arrow-warning.disabled, .btn-clear-arrow-warning:disabled {
    color: #f5ca67;
    background-color: transparent; }
  .btn-clear-arrow-warning:not(:disabled):not(.disabled):active, .btn-clear-arrow-warning:not(:disabled):not(.disabled).active,
  .show > .btn-clear-arrow-warning.dropdown-toggle {
    color: #f5ca67; }
    .btn-clear-arrow-warning:not(:disabled):not(.disabled):active:focus, .btn-clear-arrow-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-clear-arrow-warning.dropdown-toggle:focus {
      box-shadow: 0 1.4rem 0 -1.2rem rgba(245, 202, 103, 0.5); }
  .btn-clear-arrow-warning::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #f5ca67;
    border-right: 2px solid #f5ca67;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-clear-arrow-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  padding-right: 3.84rem;
  position: relative; }
  .btn-clear-arrow-danger:hover {
    color: #c82333; }
  .btn-clear-arrow-danger:focus, .btn-clear-arrow-danger.focus {
    box-shadow: 0 1.4rem 0 -1.2em rgba(220, 53, 69, 0.5); }
  .btn-clear-arrow-danger.disabled, .btn-clear-arrow-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-clear-arrow-danger:not(:disabled):not(.disabled):active, .btn-clear-arrow-danger:not(:disabled):not(.disabled).active,
  .show > .btn-clear-arrow-danger.dropdown-toggle {
    color: #dc3545; }
    .btn-clear-arrow-danger:not(:disabled):not(.disabled):active:focus, .btn-clear-arrow-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-clear-arrow-danger.dropdown-toggle:focus {
      box-shadow: 0 1.4rem 0 -1.2rem rgba(220, 53, 69, 0.5); }
  .btn-clear-arrow-danger::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #dc3545;
    border-right: 2px solid #dc3545;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-clear-arrow-light {
  color: #fff;
  background-color: transparent;
  background-image: none;
  padding-right: 3.84rem;
  position: relative; }
  .btn-clear-arrow-light:hover {
    color: #ececec; }
  .btn-clear-arrow-light:focus, .btn-clear-arrow-light.focus {
    box-shadow: 0 1.4rem 0 -1.2em rgba(255, 255, 255, 0.5); }
  .btn-clear-arrow-light.disabled, .btn-clear-arrow-light:disabled {
    color: #fff;
    background-color: transparent; }
  .btn-clear-arrow-light:not(:disabled):not(.disabled):active, .btn-clear-arrow-light:not(:disabled):not(.disabled).active,
  .show > .btn-clear-arrow-light.dropdown-toggle {
    color: #fff; }
    .btn-clear-arrow-light:not(:disabled):not(.disabled):active:focus, .btn-clear-arrow-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-clear-arrow-light.dropdown-toggle:focus {
      box-shadow: 0 1.4rem 0 -1.2rem rgba(255, 255, 255, 0.5); }
  .btn-clear-arrow-light::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-clear-arrow-dark {
  color: #491a76;
  background-color: transparent;
  background-image: none;
  padding-right: 3.84rem;
  position: relative; }
  .btn-clear-arrow-dark:hover {
    color: #361357; }
  .btn-clear-arrow-dark:focus, .btn-clear-arrow-dark.focus {
    box-shadow: 0 1.4rem 0 -1.2em rgba(73, 26, 118, 0.5); }
  .btn-clear-arrow-dark.disabled, .btn-clear-arrow-dark:disabled {
    color: #491a76;
    background-color: transparent; }
  .btn-clear-arrow-dark:not(:disabled):not(.disabled):active, .btn-clear-arrow-dark:not(:disabled):not(.disabled).active,
  .show > .btn-clear-arrow-dark.dropdown-toggle {
    color: #491a76; }
    .btn-clear-arrow-dark:not(:disabled):not(.disabled):active:focus, .btn-clear-arrow-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-clear-arrow-dark.dropdown-toggle:focus {
      box-shadow: 0 1.4rem 0 -1.2rem rgba(73, 26, 118, 0.5); }
  .btn-clear-arrow-dark::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.2rem;
    height: 0.5rem;
    width: 0.5rem;
    border-bottom: 2px solid #491a76;
    border-right: 2px solid #491a76;
    transform: rotate(-45deg) translate(50%, -50%); }

.btn-link {
  font-weight: 400;
  color: #7165e5;
  background-color: transparent; }
  .btn-link:hover {
    color: #3625d9;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-wide {
  padding: 0.6rem 2.4rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem; }

.btn-xwide {
  padding: 0.6rem 6rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #7165e5; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -2px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.9rem;
  padding-left: 0.9rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -2px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file:focus {
      z-index: 3; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -2px; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -2px; }

.input-group-prepend {
  margin-right: -2px; }

.input-group-append {
  margin-left: -2px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.6rem 1.2rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 2px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #7165e5; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(113, 101, 229, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #fefeff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #dee2e6; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #7165e5; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #7165e5; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(113, 101, 229, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(113, 101, 229, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #7165e5; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(113, 101, 229, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.7rem + 4px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 2px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #d6d2f7;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(214, 210, 247, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 4px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 4px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.7rem + 4px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.7rem + 4px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #d6d2f7;
    box-shadow: 0 0 0 0.2rem rgba(113, 101, 229, 0.25); }
    .custom-file-input:focus ~ .custom-file-label::after {
      border-color: #d6d2f7; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.7rem + 4px);
  padding: 0.6rem 1.2rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 2px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.7rem;
    padding: 0.6rem 1.2rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 2px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #7165e5;
    border: 0;
    border-radius: 1rem;
    appearance: none; }
    .custom-range::-webkit-slider-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(113, 101, 229, 0.25); }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #fefeff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #7165e5;
    border: 0;
    border-radius: 1rem;
    appearance: none; }
    .custom-range::-moz-range-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(113, 101, 229, 0.25); }
    .custom-range::-moz-range-thumb:active {
      background-color: #fefeff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #7165e5;
    border: 0;
    border-radius: 1rem;
    appearance: none; }
    .custom-range::-ms-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(113, 101, 229, 0.25); }
    .custom-range::-ms-thumb:active {
      background-color: #fefeff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d; }

.nav-tabs {
  border-bottom: 2px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -2px; }
  .nav-tabs .nav-link {
    border: 2px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -2px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #7165e5; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 809.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 810px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 1045.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1046px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1209.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1210px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 2px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 2px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 2px) calc(0.25rem - 2px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 2px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 2px) calc(0.25rem - 2px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 2px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 2px);
  border-top-right-radius: calc(0.25rem - 2px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 2px);
  border-bottom-left-radius: calc(0.25rem - 2px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0; }

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0; }

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -2px;
  line-height: 1.25;
  color: #7165e5;
  background-color: #fff;
  border: 2px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #3625d9;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(113, 101, 229, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #7165e5;
  border-color: #7165e5; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #7165e5; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #4939de; }

.badge-primary-darker {
  color: #fff;
  background-color: #5142df; }
  .badge-primary-darker[href]:hover, .badge-primary-darker[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #3222cc; }

.badge-primary-darkest {
  color: #fff;
  background-color: #270845; }
  .badge-primary-darkest[href]:hover, .badge-primary-darkest[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #0d0317; }

.badge-secondary {
  color: #212529;
  background-color: #f5ca67; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #f2b937; }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1e7e34; }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #117a8b; }

.badge-warning {
  color: #212529;
  background-color: #f5ca67; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #f2b937; }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #bd2130; }

.badge-light {
  color: #212529;
  background-color: #fff; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #e6e6e6; }

.badge-dark {
  color: #fff;
  background-color: #491a76; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #2f114c; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 2px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #3b3577;
  background-color: #e3e0fa;
  border-color: #d7d4f8; }
  .alert-primary hr {
    border-top-color: #c3bef4; }
  .alert-primary .alert-link {
    color: #2a2554; }

.alert-primary-darker {
  color: #2a2274;
  background-color: #dcd9f9;
  border-color: #cecaf6; }
  .alert-primary-darker hr {
    border-top-color: #bab4f2; }
  .alert-primary-darker .alert-link {
    color: #1c164d; }

.alert-primary-darkest {
  color: #140424;
  background-color: #d4ceda;
  border-color: #c3bacb; }
  .alert-primary-darkest hr {
    border-top-color: #b6abc0; }
  .alert-primary-darkest .alert-link {
    color: black; }

.alert-secondary {
  color: #7f6936;
  background-color: #fdf4e1;
  border-color: #fcf0d4; }
  .alert-secondary hr {
    border-top-color: #fae8bc; }
  .alert-secondary .alert-link {
    color: #5b4b27; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #7f6936;
  background-color: #fdf4e1;
  border-color: #fcf0d4; }
  .alert-warning hr {
    border-top-color: #fae8bc; }
  .alert-warning .alert-link {
    color: #5b4b27; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #858585;
  background-color: white;
  border-color: white; }
  .alert-light hr {
    border-top-color: #f2f2f2; }
  .alert-light .alert-link {
    color: #6c6c6c; }

.alert-dark {
  color: #260e3d;
  background-color: #dbd1e4;
  border-color: #ccbfd9; }
  .alert-dark hr {
    border-top-color: #bfafd0; }
  .alert-dark .alert-link {
    color: #0c0414; }

@keyframes progress-bar-stripes {
  from {
    background-position: 2rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 2rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #7165e5;
  transition: width 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 2rem 2rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -2px;
  background-color: #fff;
  border: 2px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #7165e5;
    border-color: #7165e5; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #3b3577;
  background-color: #d7d4f8; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #3b3577;
    background-color: #c3bef4; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #3b3577;
    border-color: #3b3577; }

.list-group-item-primary-darker {
  color: #2a2274;
  background-color: #cecaf6; }
  .list-group-item-primary-darker.list-group-item-action:hover, .list-group-item-primary-darker.list-group-item-action:focus {
    color: #2a2274;
    background-color: #bab4f2; }
  .list-group-item-primary-darker.list-group-item-action.active {
    color: #fff;
    background-color: #2a2274;
    border-color: #2a2274; }

.list-group-item-primary-darkest {
  color: #140424;
  background-color: #c3bacb; }
  .list-group-item-primary-darkest.list-group-item-action:hover, .list-group-item-primary-darkest.list-group-item-action:focus {
    color: #140424;
    background-color: #b6abc0; }
  .list-group-item-primary-darkest.list-group-item-action.active {
    color: #fff;
    background-color: #140424;
    border-color: #140424; }

.list-group-item-secondary {
  color: #7f6936;
  background-color: #fcf0d4; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #7f6936;
    background-color: #fae8bc; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #7f6936;
    border-color: #7f6936; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #7f6936;
  background-color: #fcf0d4; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #7f6936;
    background-color: #fae8bc; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #7f6936;
    border-color: #7f6936; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #858585;
  background-color: white; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-dark {
  color: #260e3d;
  background-color: #ccbfd9; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #260e3d;
    background-color: #bfafd0; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #260e3d;
    border-color: #260e3d; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
    @media screen and (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 2px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 2px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 1046px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 2px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 2px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 2px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 2px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 2px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 2px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 2px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 2px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 2px;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 2px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 2px);
  border-top-right-radius: calc(0.3rem - 2px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px; }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0; }

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-fade .carousel-item-next,
    .carousel-fade .carousel-item-prev,
    .carousel-fade .carousel-item.active,
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-prev {
      transform: translate3d(0, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #7165e5 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #4939de !important; }

.bg-primary-darker {
  background-color: #5142df !important; }

a.bg-primary-darker:hover, a.bg-primary-darker:focus,
button.bg-primary-darker:hover,
button.bg-primary-darker:focus {
  background-color: #3222cc !important; }

.bg-primary-darkest {
  background-color: #270845 !important; }

a.bg-primary-darkest:hover, a.bg-primary-darkest:focus,
button.bg-primary-darkest:hover,
button.bg-primary-darkest:focus {
  background-color: #0d0317 !important; }

.bg-secondary {
  background-color: #f5ca67 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #f2b937 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #f5ca67 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #f2b937 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #fff !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e6e6e6 !important; }

.bg-dark {
  background-color: #491a76 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #2f114c !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 2px solid #dee2e6 !important; }

.border-top {
  border-top: 2px solid #dee2e6 !important; }

.border-right {
  border-right: 2px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 2px solid #dee2e6 !important; }

.border-left {
  border-left: 2px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-3 {
  border: 3px solid #dee2e6 !important; }

.border-top-3 {
  border-top: 3px solid #dee2e6 !important; }

.border-right-3 {
  border-right: 3px solid #dee2e6 !important; }

.border-bottom-3 {
  border-bottom: 3px solid #dee2e6 !important; }

.border-left-3 {
  border-left: 3px solid #dee2e6 !important; }

.border-1 {
  border: 1px solid #dee2e6 !important; }

.border-top-1 {
  border-top: 1px solid #dee2e6 !important; }

.border-right-1 {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom-1 {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left-1 {
  border-left: 1px solid #dee2e6 !important; }

.border-primary {
  border-color: #7165e5 !important; }

.border-primary-darker {
  border-color: #5142df !important; }

.border-primary-darkest {
  border-color: #270845 !important; }

.border-secondary {
  border-color: #f5ca67 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #f5ca67 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #fff !important; }

.border-dark {
  border-color: #491a76 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.cursor-pointer {
  cursor: pointer; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 810px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1046px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1210px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 810px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1046px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1210px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 810px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1046px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1210px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-y {
  overflow-y: auto;
  overflow-x: hidden; }

.overflow-x {
  overflow-y: hidden;
  overflow-x: auto; }

.overflow-hidden {
  overflow-y: hidden;
  overflow-x: hidden; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.z--1 {
  z-index: -1; }

.z-0 {
  z-index: 0; }

.z-1 {
  z-index: 1; }

.z-2 {
  z-index: 2; }

.z-3 {
  z-index: 3; }

.z-4 {
  z-index: 4; }

.z-5 {
  z-index: 5; }

.top-0 {
  top: 0; }

.left-0 {
  left: 0; }

.bottom-0 {
  bottom: 0; }

.right-0 {
  right: 0; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.h-100vh {
  height: 100vh; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 810px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1046px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1210px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 810px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1046px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1210px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-black {
  color: #000 !important; }

.text-primary {
  color: #7165e5 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #4939de !important; }

.text-primary-darker {
  color: #5142df !important; }

a.text-primary-darker:hover, a.text-primary-darker:focus {
  color: #3222cc !important; }

.text-primary-darkest {
  color: #270845 !important; }

a.text-primary-darkest:hover, a.text-primary-darkest:focus {
  color: #0d0317 !important; }

.text-secondary {
  color: #f5ca67 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #f2b937 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #f5ca67 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #f2b937 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important; }

.text-light {
  color: #fff !important; }

a.text-light:hover, a.text-light:focus {
  color: #e6e6e6 !important; }

.text-dark {
  color: #491a76 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #2f114c !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #999 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none; }
  .text-decoration-none:hover {
    text-decoration: none; }

.hover-text-primary:hover {
  color: #7165e5 !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.transparent {
  opacity: 0.5; }

.no-style {
  color: inherit; }

.no-style:hover {
  text-decoration: none;
  color: inherit; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 2px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 1046px !important; }
  .container {
    min-width: 1046px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 2px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }


/* -------------------------------------------------------------------------- *\
  Modals Component
\* -------------------------------------------------------------------------- */
.modal__window-container {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.modal__window-container--hidden {
  display: none;
}

.modal__close {
  height: 50px;
  width: 50px;
  padding: 10px;
  box-sizing: border-box;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal__card {
  background-color: white;
  border-radius: 5px;
  max-width: 100%;
  padding: 30px;
  margin-top: 60px;
  margin-bottom: 10px;
  box-sizing: border-box;
  flex: 0 1 auto;
  position: relative;
}

@media screen and (max-width: 700px) {
  .modal__card {
    padding: 10px;
  } }

.modal__card .ps__rail-y {
  opacity: 0.5;
  margin-top: 2px;
  margin-bottom: 2px;
}

.modal__card .ps__rail-x {
  opacity: 0.5;
  margin-left: 2px;
  margin-right: 2px;
}

.modal__row {
  display: flex;
  justify-content: space-between;
  max-width: 700px;
}

.modal__row +
.modal__row {
  border-top: 2px solid #dee2e6;
  margin-top: 15px;
  padding-top: 15px;
}

.modal__row > * {
  margin-left: 10px;
}

.modal__row > *:first-child {
  margin-left: 0;
}

@media screen and (max-width: 700px) {
  .modal__row {
    flex-direction: column;
  }

  .modal__row > * {
    margin-left: 0;
    margin-top: 10px;
  }

  .modal__row > *:first-child {
    margin-top: 0;
  }
}

.modal__row--gray {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-top: 20px !important;
  flex-direction: column;
}

.modal__row--gray > * {
  margin-left: 0;
  margin-top: 10px;
}

@media screen and (max-width: 700px) {
  .modal__row--gray {
    margin: -10px;
    padding: 10px;
  }
}

.modal__row--gray .account__card-bottom-item + .account__card-bottom-item {
  margin-left: 20px;
}

@media screen and (max-width: 700px) {
  .modal__row--gray .account__card-bottom-item + .account__card-bottom-item {
    margin-left: 0;
  }
}

.modal__sub-row {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 700px) {
  .modal__sub-row {
    flex-direction: column;
    margin-top: 0;
  }
}

.modal__sub-row + .modal__sub-row {
  margin-top: 40px !important;
}

@media screen and (max-width: 700px) {
  .modal__sub-row + .modal__sub-row {
    margin-top: 0 !important;
  }
}

.modal__sub-row--line {
  border-top: 2px solid #dee2e6;
  padding-top: 30px;
  flex-direction: column;
}

.modal__boost-buttons {
  display: flex;
  justify-content: space-between;
}

.modal__boost-buttons > .btn {
  margin-left: 0.5rem;
}

@media screen and (max-width: 700px) {
  .modal__boost-buttons {
    flex-direction: column;
  }

  .modal__boost-buttons > .btn {
    margin-left: 0;
    margin-top: 0.5rem;
  }
}

.modal__center-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.modal__title {
  font-size: 2rem;
  align-self: center;
  margin-bottom: 0;
}

@media screen and (max-width: 700px) {
  .modal__title {
    font-size: 1.5rem;
  }
}

.modal__subtitle {
  color: #7165e5;
  margin-bottom: 0;
  font-size: 1.5rem;
  text-transform: capitalize;
}

@media screen and (max-width: 700px) {
  .modal__subtitle {
    font-size: 1rem;
  }
}

.modal__seller-info {
  display: flex;
}

.modal__label {
  text-transform: uppercase;
  margin-bottom: 0;
}

@media screen and (max-width: 700px) {
  .modal__label {
    font-size: 0.8rem;
  }
}

.modal__seller-into-item-value {
  font-size: 2rem;
  color: #7165e5;
  margin-bottom: 0;
}

@media screen and (max-width: 700px) {
  .modal__seller-into-item-value {
    font-size: 1.5rem;
  }
}

.modal__seller-into-item-icon {
  margin-top: 8px;
  height: 2rem;
}

@media screen and (max-width: 700px) {
  .modal__seller-into-item-icon {
    height: 1.5rem;
  }
}

.modal__seller-info-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.modal__seller-info-item +
.modal__seller-info-item {
  border-left: 2px solid #dee2e6;
}

.modal__seller-info-item .modal__title {
  text-align: center;
}

.modal__row-item {
  display: flex;
  flex-direction: column;
}

.modal__dice-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 700px) {
  .modal__dice-text-container {
    align-items: center;
  }
}

.modal__row-item .modal__title {
  margin-bottom: 10px;
}

.modal__button-group {
  align-self: stretch;
}

.modal__button-group > .btn {
  flex: 1 1 auto;
}

.modal__textarea {
  width: 100%;
  border: 2px solid #dee2e6;
  border-radius: 3px;
  transition: box-shadow 0.2s;
  padding: 5px;
}

.modal__textarea:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(113, 101, 229, 0.25);
}

.modal__small-text {
  font-size: 0.8rem;
  opacity: 0.5;
  align-self: center;
  margin-bottom: 0;
}

.modal__dice {
  height: 200px;
}

.modal__check-mark {
  margin-bottom: 20px;
}

.modal__product-image {
  height: 100px;
  width: 100px;
  background-color: gray;
  border-radius: 5px;
}

.modal__new-date {
  text-align: center;
  width: 100%;
}

@media screen and (max-width: 700px) {
  .modal__new-date {
    text-align: right;
  }
}

.modal__time {
  color: #7165e5;
  font-size: 3rem;
}

.modal__card-center-content {
  text-align: center;
}

.modal__watching {
  font-size: 0.8rem;
  opacity: 0.5;
}

.modal__watching img {
  margin-right: 0.5rem;
  margin-bottom: 0.2rem;
}


/* -------------------------------------------------------------------------- *\
  Sign In Page
\* -------------------------------------------------------------------------- */
.sign-in__background {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0; }

.sign-in__content {
  width: 450px;
  margin: 0 auto;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center; }

@media screen and (max-width: 470px) {
  .sign-in__content {
    width: unset;
    margin: 0 10px;
  } }

.sign-in__logo {
  width: 120px;
  margin: 0 auto;
  display: block;
  margin-bottom: 30px; }

.sign-in__background-image {
  height: 100%;
  width: 100%;
  position: absolute; }

.sign-in__background-color {
  height: 100%;
  width: 100%;
  background-color: rgba(122, 71, 194, 0.95);
  position: absolute; }

.sign-in__card {
  background-color: white;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 35px;
  border-radius: 6px; }

.sign-in-admin__content {
  width: 450px;
  margin: 0 auto;
  position: relative; }

.sign-in-admin__card {
  background-color: white;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 35px;
  border: 1px solid #007bff; }

.sign-in-admin__greeting {
  text-align: center;
  color: #7165e5;
  font-size: 15px;
  margin: 10px 0 40px; }

.sign-in-admin__input {
  outline: none;
  padding: 15px;
  border-radius: 3px;
  border: 1px solid #e9ecef; }

.sign-in-admin__input::placeholder {
  color: #adb5bd; }

.sign-in-admin__input + .sign-in-admin__input {
  margin-top: 15px; }

.sign-in-admin__options {
  display: flex;
  font-size: 11px;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0; }

.sign-in-admin__forgot {
  color: #7165e5; }

.sign-in-admin__options .toggle {
  margin-bottom: 0; }

.sign-in-admin__options .toggle span {
  font-size: 11px;
  color: #adb5bd; }

.sign-in-admin__what {
  color: #007bff;
  text-align: center;
  font-size: 14px;
  margin-top: 30px; }

.sign-in-admin + .footer {
  bottom: 0;
  right: 0;
  width: 100%;
  position: absolute; }

/* -------------------------------------------------------------------------- *\
  Register Page
\* -------------------------------------------------------------------------- */
.register__terms {
  color: white;
  font-size: 1rem;
  text-align: center;
}

.register__terms span {
  color: #f5ca67;
}

/* -------------------------------------------------------------------------- *\
  Search Page
\* -------------------------------------------------------------------------- */
.search__header {
  width: 650px;
  margin: 0 auto;
  margin-top: 100px; }

@media screen and (min-width: 1046px) {
  .search__header {
    width: 900px; } }


/* -------------------------------------------------------------------------- *\
  Submit Ticket Page
\* -------------------------------------------------------------------------- */
.ticket__form-main {
  display: flex;
  margin-bottom: 50px; }

.ticket__form-left {
  flex: 50% 0 0;
  display: flex;
  flex-direction: column; }

.ticket__from-upload {
  height: 200px;
  flex: 1 1 auto;
  width: 100%;
  border: 1px solid #e9ecef;
  border-radius: 3px; }

.ticket__form-title {
  color: #7165e5;
  font-weight: 400;
  font-size: 35px;
  margin: 0; }

.ticket__form-subtitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px; }

.ticket__form-text {
  font-weight: 100;
  color: #adb5bd;
  font-size: 14px;
  line-height: 22px; }

.ticket__form-right {
  margin-right: 20px;
  flex: 50% 0 0;
  display: flex;
  flex-direction: column; }

.ticket__from-input {
  width: 100%;
  padding: 20px 10px;
  border-radius: 3px;
  border: 1px solid #e9ecef;
  margin-bottom: 20px;
  font-size: 14px;
  outline: none; }
  .ticket__from-input::placeholder {
    color: #adb5bd; }

.ticket__from-input--message {
  flex: 1 1 auto;
  margin-bottom: 0; }

.ticket__form-drop-down-container {
  position: relative; }
  .ticket__form-drop-down-container::after {
    content: "";
    position: absolute;
    height: 8px;
    width: 8px;
    border-bottom: 2px solid #adb5bd;
    border-right: 2px solid #adb5bd;
    transform: rotate(45deg);
    top: 22px;
    right: 18px; }

.ticket__from-drop-down {
  width: 100%;
  padding: 20px 10px;
  border: 1px solid #e9ecef;
  border-radius: 3px;
  background-color: transparent;
  appearance: none;
  margin-bottom: 20px;
  color: #adb5bd;
  font-size: 14px;
  outline: none;
  position: relative; }

/* -------------------------------------------------------------------------- *\
  What Page - Header
\* -------------------------------------------------------------------------- */

.what__main {
  margin-bottom: 100px; }

@media screen and (max-width: 810px) {
  header.what__header {
    margin: 0 !important
  } }

.what__header-background {
  height: 500px;
  width: 100%;
  background-position: center;
  background-size: cover;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  align-items: center;
  margin-right: 120px; }
  
@media screen and (max-width: 810px) {
  .what__header-background {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  } }

.what__header-title {
  margin: 0;
  width: 100%;
  color: white;
  font-weight: 400;
  margin-left: 0;
  margin: 0 50px;
  font-size: 35px;
  line-height: 45px; }

@media screen and (min-width: 1046px) {
  .what__header-title {
    width: 680px;
    margin-left: 100px; } }

/* -------------------------------------------------------------------------- *\
  What Page - Business Structure
\* -------------------------------------------------------------------------- */
.what__structure {
  display: flex;
  align-items: center;
  width: 650px;
  margin: 0 auto;
  height: unset;
  margin-bottom: 60px;
  flex-direction: column; }

@media screen and (min-width: 1300px) {
  .what__structure {
    width: 1000px;
    margin: 0 auto;
    height: 350px;
    flex-direction: row; } }

@media screen and (max-width: 670px) {
  .what__structure {
    width: unset;
    margin: 0 10px;
  } }

.what__structure-cards {
  display: flex; }

@media screen and (max-width: 670px) {
  .what__structure-cards {
    flex-direction: column;
    width: 100%; } }

.what__structure-card {
  background-color: #7165e5;
  color: #f5ca67;
  height: 280px;
  width: 200px;
  border-radius: 6px;
  padding: 25px;
  position: relative;
  margin: 0 6px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.3);
  transition: transform 0.3s, margin 0.3s, box-shadow 0.3s; }

@media screen and (max-width: 670px) {
  .what__structure-card {
    width: unset;
    height: unset;
    margin-bottom: 15px;
    align-items: center;
  }
}

.what__structure-card--isSelected {
  transform: scale(1.1);
  margin: 0 15px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3); }

@media screen and (max-width: 670px) {
  .what__structure-card--isSelected {
    margin: 0 15px 15px;}
}

.what__structure-card-image {
  height: 60px;
  width: 60px;
  background-color: rgba(173, 216, 230, 0.3); }

@media screen and (max-width: 670px) {
  .what__structure-card-image {
    margin: 0 auto 15px; } }

.what__structure-card-title {
  position: absolute;
  bottom: 0;
  left: 20px;
  font-size: 16px;
  font-weight: 400; }

@media screen and (max-width: 670px) {
  .what__structure-card-title {
    text-align: center;
  } }

.what__structure-info {
  margin-left: 0;
  margin-top: 40px; }

@media screen and (min-width: 1300px) {
  .what__structure-info {
    margin-left: 20px;
    margin-top: 0; } }

.what__structure-info-title {
  color: #7165e5;
  font-weight: 400;
  font-size: 24px; }

.what__structure-info-subtitle {
  font-weight: 400;
  color: #adb5bd; }

.what__structure-info-description {
  color: #adb5bd;
  font-size: 14px;
  line-height: 18px; }

.what__structure-info-item {
  display: none; }

.what__structure-info-item--isSelected {
  display: block; }

/* -------------------------------------------------------------------------- *\
  What Page - Process
\* -------------------------------------------------------------------------- */
.what__process {
  margin-bottom: 60px;
  width: 100%;
  padding: 0 50px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative; }

@media screen and (min-width: 1300px) {
  .what__process {
    width: 1000px;
    padding: 0;
    flex-direction: row; } }

.what__process-info {
  height: unset;
  align-self: flex-start;
  width: 650px;
  margin: 0 auto; }

@media screen and (min-width: 1300px) {
  .what__process-info {
    position: relative;
    height: 350px;
    align-self: unset;
    width: unset;
    margin: 0; } }

.what__process-decoration {
  height: 130px;
  width: 1px;
  background-color: #7165e5;
  position: absolute;
  top: 30px;
  left: 30px;
  opacity: 0.5;
  display: none; }
  .what__process-decoration::before {
    content: "";
    height: 8px;
    width: 8px;
    background-color: #7165e5;
    position: absolute;
    bottom: 0;
    left: -4px;
    border-radius: 50%; }

@media screen and (min-width: 1300px) {
  .what__process-decoration {
    display: block; } }

.what__process-title {
  font-weight: 400;
  font-size: 25px;
  margin-left: 0px; }

@media screen and (min-width: 1300px) {
  .what__process-title {
    margin-left: 80px; } }

.what__process-info-slides {
  height: 100%;
  display: flex;
  align-items: center;
  display: none; }

@media screen and (min-width: 1300px) {
  .what__process-info-slides {
    display: flex; } }

.what__process-info-slides--tablet {
  display: block; }

@media screen and (min-width: 1300px) {
  .what__process-info-slides--tablet {
    display: none; } }

.what__process-info-slide {
  display: none;
  width: 350px;
  margin-left: 80px;
  position: relative; }

@media screen and (max-width: 650px) {
  .what__process-info-slide {
    width: unset;
    margin-left: 50px;
  }
}

.what__process-info-slide--isSelected {
  display: block; }

.what__process-info-slide-title {
  color: #7165e5;
  font-size: 16px;
  font-weight: 400; }

.what__process-info-slide-number {
  position: absolute;
  left: -55px;
  top: 50%;
  font-size: 80px;
  transform: translate(-50%, -50%);
  color: rgba(113, 101, 229, 0.5);
  font-style: normal; }

@media screen and (max-width: 650px) {
  .what__process-info-slide-number {
    font-size: 50px;
  }
}

.what__process-info-slide-description {
  color: #adb5bd;
  font-size: 14px; }

.what__process-slider {
  height: 500px;
  overflow: hidden;
  position: relative;
  margin-bottom: 30px; }

.what__process-slider-track {
  transition: transform 0.5s; }

.what__process-slider-track--slide1 {
  transform: translateY(125px); }

.what__process-slider-track--slide2 {
  transform: translateY(-125px); }

.what__process-slider-track--slide3 {
  transform: translateY(-375px); }

.what__process-slider-track--slide4 {
  transform: translateY(-625px); }

.what__process-slider-gradient {
  height: 100%;
  width: 100%;
  background: linear-gradient(white, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), white);
  position: absolute;
  top: 0;
  pointer-events: none; }

.what__process-slider-slide-image {
  height: 250px;
  width: 500px;
  background-color: #fbfbfb;
  border-radius: 4px;
  border: 1px solid #007bff; }

@media screen and (max-width: 650px) {
  .what__process-slider-slide-image {
    height: 200px;
    width: 220px;
  } }

.what__process-slider-slide {
  transform: scale(0.9);
  transition: transform 0.5s;
  cursor: pointer; }

.what__process-slider-slide--isSelected {
  transform: scale(1);
  cursor: default; }

.what__process-dots {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  right: 50%;
  transform: translateX(290px);
  bottom: 385px; }

@media screen and (max-width: 650px) {
  .what__process-dots {
    transform: translateX(130px);
    bottom: 400px;
  } }

@media screen and (min-width: 1300px) {
  .what__process-dots {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: unset;
    right: 50%;
    transform: unset;
    bottom: 200px; } }

.what__process-dot {
  height: 10px;
  width: 10px;
  border: 1px solid #7165e5;
  margin: 8px 0;
  border-radius: 50%;
  cursor: pointer; }

.what__process-dot--isSelected {
  background-color: #7165e5; }

/* -------------------------------------------------------------------------- *\
  Blog Page - Header
\* -------------------------------------------------------------------------- */
.blog__header {
  margin: 0 50px;
}

@media screen and (max-width: 810px) {
  .blog__header {
    margin: 0;
  }
}

.blog__header-background {
  height: 500px;
  width: 100%;
  background-position: center;
  background-size: cover;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  align-items: center;
  margin-right: 120px; }

@media screen and (max-width: 810px) {
  .blog__header-background {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.blog__header-content {
  margin-left: 100px; }

@media screen and (max-width: 810px) {
  .blog__header-content {
    margin-left: 10px;
  }
}

.blog__header-title {
  color: white;
  font-weight: 400;
  font-size: 40px; }

.blog__header-date {
  color: white;
  font-size: 15px;
  font-weight: 100; }

/* -------------------------------------------------------------------------- *\
  Blog Page - Top Posts
\* -------------------------------------------------------------------------- */
.blog__top-posts {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 80px;
  flex-direction: column;
  align-items: center;
  margin-top: -50px;
  margin-bottom: 50px; }

@media screen and (min-width: 1046px) {
  .blog__top-posts {
    width: 900px;
    flex-direction: row;
    padding: 0; } }

.blog__top-post {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  margin-bottom: 15px; }

@media screen and (min-width: 1046px) {
  .blog__top-post {
    width: 285px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: block; } }

@media screen and (max-width: 700px) {
  .blog__top-post {
    width: 285px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: block; } }

.blog__top-post + .blog__top-post {
  margin-top: 20px; }

@media screen and (min-width: 1046px) {
  .blog__top-post + .blog__top-post {
    margin-top: 0; } }

.blog__top-post-image {
  width: 100%;
  height: unset;
  background-color: #e9ecef; }

@media screen and (min-width: 1046px) {
  .blog__top-post-image {
    height: 180px; } }

@media screen and (max-width: 700px) {
  .blog__top-post-image {
    height: 180px; } }



.blog__top-post-info {
  padding: 20px;
  background-color: white; }

.blog__top-post-title {
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  margin-top: 0; }

.blog__top-post-text {
  color: #adb5bd;
  font-size: 14px;
  line-height: 24px; }

.blog__top-post-date {
  font-size: 12px;
  font-weight: 100;
  color: #7165e5; }

/* -------------------------------------------------------------------------- *\
  Blog Page - More Posts
\* -------------------------------------------------------------------------- */
.blog__posts {
  overflow: hidden; }

.blog__posts-background {
  height: 100%;
  width: 200%;
  position: absolute;
  background-color: #f9f9f9;
  transform: rotate(-2.5deg) translateX(-25%); }

.blog__posts-content {
  width: 650px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  padding-bottom: 50px;
  padding-top: 100px; }

@media screen and (min-width: 1046px) {
  .blog__posts-content {
    width: 900px; } }

@media screen and (max-width: 700px) {
  .blog__posts-content {
    width: 280px; } }

.blog__post {
  width: 280px;
  margin-bottom: 30px; }

.blog__post-image {
  height: 200px;
  width: 100%;
  background-color: #e9ecef;
  border-radius: 8px; }

.blog__post-title {
  font-size: 17px;
  font-weight: 400;
  line-height: 25px; }

.blog__post-text {
  font-size: 14px;
  color: #adb5bd;
  line-height: 24px; }

.blog__post-date {
  font-size: 12px;
  font-weight: 100;
  color: #7165e5; }

/* -------------------------------------------------------------------------- *\
  Home Page - Hero Slider
\* -------------------------------------------------------------------------- */
.home__slider {
  height: 600px;
  overflow: hidden;
  color: white;
  clip-path: polygon(0% 0%, 100% 0%, 100% 95%, 0% 100%); }

@media screen and (min-width: 810px) {
  .home__slider {
    height: 500px;
    clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 0% 100%); } }

.home__slide {
  height: 500px !important;
  outline: none;
  position: relative; }

.home__slider .slick-dots {
  text-align: right;
  bottom: 120px;
  padding-right: 50px; }

@media screen and (min-width: 1046px) {
  .home__slider .slick-dots {
    bottom: 80px; } }

.home__slider .slick-dots li {
  margin: 0;
  width: 18px;
  height: 18px; }

.home__slider .slick-dots li button {
  padding: 0;
  width: 18px;
  height: 18px; }

.home__slider .slick-dots li button:before {
  width: 18px;
  height: 18px;
  font-size: 7px;
  color: white;
  transition: all 0.3s;
  opacity: 0.5; }

.home__slider .slick-dots li.slick-active button:before {
  color: #f5ca67;
  opacity: 1; }

.home__slide-image {
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  position: absolute; }

.home__slide-content {
  position: absolute;
  margin-left: 15px;
  max-width: 380px;
  margin-right: 15px;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 400px; }

@media screen and (min-width: 810px) {
  .home__slide-content {
    position: absolute;
    margin-left: 100px;
    margin-right: 0;
    margin-top: 80px;
    height: unset; } }

.home__discount-container {
  width: 100%;
  margin-top: -135px;
  margin-bottom: 50px;
  position: relative; }

/* -------------------------------------------------------------------------- *\
  Landing Page
\* -------------------------------------------------------------------------- */
.landing__main {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px;
}

@media screen and (max-width: 600px) {
  .landing__main {
    padding: 10px;
  }
}

.landing__wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.landing__logo {
  width: 150px;
  margin-bottom: 3rem;
}

.landing__title {
  color: white;
  font-size: 4rem;
}

@media screen and (max-width: 600px) {
  .landing__title {
    font-size: 2rem;
  }
}

.landing__title span {
  color: #f5ca67;
}

.landing__paragraph {
  color: white;
  max-width: 600px;
}

.landing__background {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.landing__background-gradient {
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(#7A47C2, rgba(122, 71, 194, 0.94));;
}

.landing__background-image {
  position: absolute;
  height: 100%;
  width: 100%;
}

.landing__shadow {
  box-shadow: 0 0 30px #270845;
}

/* -------------------------------------------------------------------------- *\
  Home Page - Featured
\* -------------------------------------------------------------------------- */
.home__featured {
  margin-bottom: 50px;
  padding-bottom: 50px;
  width: unset;
  margin: 0;
  margin-bottom: 30px; }

@media screen and (min-width: 402px) {
  .home__featured {
    margin: 0 40px;
    margin-bottom: 50px; } }

@media screen and (min-width: 810px) {
  .home__featured {
    width: 650px;
    margin: 0 auto; } }

@media screen and (min-width: 1046px) {
  .home__featured {
    width: 850px; } }

.home__featured-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto; }

/* -------------------------------------------------------------------------- *\
  Home Page - Categories
\* -------------------------------------------------------------------------- */
.home__categories-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 320px;
  flex: 0 0 auto; }

@media screen and (min-width: 970px) {
  .home__categories-list {
    width: 570px; } }

@media screen and (min-width: 1046px) {
  .home__categories-list {
    width: 570px; } }

.home__categories-list-item {
  width: 145px;
  height: 145px;
  margin: 5px;
  background: #adb5bd;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 20px; }

@media screen and (min-width: 970px) {
  .home__categories-list-item {
    width: 180px;
    height: 180px;
    margin: 5px;
    background: #adb5bd;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 20px; } }

.home__categories-info {
  flex: 1 1 auto;
  margin-left: 0; }

@media screen and (min-width: 810px) {
  .home__categories-info {
    flex: 1 1 auto;
    margin-left: 40px; } }

.home__categories-info-title {
  color: #7165e5;
  font-weight: 400;
  font-size: 26px;
  margin: 0;
  margin-bottom: 30px; }

.home__categories-info-description {
  font-weight: 100;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 30px; }

/* -------------------------------------------------------------------------- *\
  Category Page - Title
\* -------------------------------------------------------------------------- */
.category__header {
  height: 230px;
  position: relative;
  padding-top: 80px;
  padding-left: 100px;
  overflow: hidden;
  margin-bottom: 50px; }

.category__header-background {
  width: 300%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform-origin: 0% 0%;
  transform: rotate(-2.5deg) translateX(-50%);
  overflow: hidden; }

.category__header-gradient {
  height: 100%;
  width: 100%;
  transform: rotate(2.5deg);
  position: absolute;
  background: linear-gradient(#7A47C2, rgba(122, 71, 194, 0.94)); }

.category__header-image {
  height: 200%;
  width: 200%;
  transform: rotate(2.5deg);
  position: absolute; }

/* -------------------------------------------------------------------------- *\
  Category Page - Featured
\* -------------------------------------------------------------------------- */
.category__featured {
  margin-bottom: 50px;
  padding-bottom: 50px;
  width: 650px;
  margin: 0 auto; }

@media screen and (min-width: 1046px) {
  .category__featured {
    width: 850px; } }

/* -------------------------------------------------------------------------- *\
  Category Page - Pre-Footer
\* -------------------------------------------------------------------------- */
.category__pre-footer {
  height: 230px;
  position: relative;
  transform: translateY(76px);
  z-index: 2; }

.category__pre-footer-background {
  width: 100%;
  height: 100%;
  z-index: -1; }

.category__pre-footer-background-gradient {
  height: 100%;
  width: 100%;
  position: absolute;
  background: linear-gradient(rgba(122, 71, 194, 0.94), #7A47C2); }

.category__pre-footer-background-image {
  height: 100%;
  width: 100%;
  position: absolute; }

/* -------------------------------------------------------------------------- *\
  Product Page - General
\* -------------------------------------------------------------------------- */
.product {
  background-color: #f8f8f8;
  margin-bottom: 75px; }

.product__spots-header {
  display: flex;
  justify-content: space-between;
  margin: 50px;
  width: 605px;
  margin: 0 auto; }

@media screen and (min-width: 1046px) {
  .product__spots-header {
    width: 910px; } }

@media screen and (max-width: 650px) {
  .product__spots-header {
    width: 300px; 
    flex-direction: column; } }

.product__available {
  flex: 0 0 auto; }

.product__available-number {
  margin: 0 6px;
  display: inline-block;
  color: #adb5bd;
  cursor: pointer; }

.product__available-text {
  display: inline-block;
  color: #adb5bd; }

.product__available input[type="radio"] {
  display: none;
  cursor: pointer; }

.product__available input[type="radio"]:checked + p {
  color: #7165e5; }

.product__row {
  display: flex;
  justify-content: space-between;
  width: 605px;
  margin: 0 auto;
  align-items: center; }

@media screen and (min-width: 1046px) {
  .product__row {
    width: 910px; } }

@media screen and (max-width: 650px) {
  .product__row {
    width: 300px;
    flex-direction: column; } }

.product__pagination {
  display: flex;
}

.product__pagination--hidden {
  display: none;
}

.product__pagination-ul {
  padding: 0;
  margin: 0;
  text-align: right; }

.product__pagination-li {
  display: inline-block;
  margin-right: 30px;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 30px;
}

.product__pagination-li--active {
  background-color: #7165e5;
  color: white;
  border-radius: 3px;
}

/* -------------------------------------------------------------------------- *\
  Product Page - Top section
\* -------------------------------------------------------------------------- */
.product__top-section {
  height: 600px;
  display: flex;
}

@media screen and (min-width: 810px) {
  .product__top-section {
    clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 0% 100%);
  }
}

@media screen and (max-width: 809px) {
  .product__top-section {
    flex-direction: column;
    height: unset;
  }
}


.product__images-section {
  width: 50%;
  position: relative;
  display: flex;
}

@media screen and (max-width: 809px) {
  .product__images-section {
    height: 600px;
    flex: 1 1 auto;
    width: unset;
  }
}


.product__large-image {
  position: absolute;
  height: 530px;
  width: 100%;
  background-image: url('./assets/product.png');
  background-size: cover;
  background-position: center;
}


.product__large-image-gradient {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 70%, black 90%);
}


.product__images-list {
  width: 100%;
  position: relative;
  align-self: flex-end;
  margin-bottom: 30px;
  padding: 0 5px;
  box-sizing: border-box;
}

/* .product__images-list .slick-slide {
  height: unset;
} */

.product__images-list .slick-dots {
  text-align: center;
  top: -30px;
  height: 25px;
}

.product__images-list .slick-dots li {
  margin: 0;
  width: 18px;
  height: 18px;
}

.product__images-list .slick-dots li button {
  padding: 0;
  width: 18px;
  height: 18px;
}

.product__images-list .slick-dots li button:before {
  width: 18px;
  height: 18px;
  font-size: 7px;
  color: white;
  transition: all 0.3s;
  opacity: 0.5;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.product__images-list .slick-dots li.slick-active button:before {
  color: #f5ca67;
  opacity: 1;
}


.product__image-list-item-container {
  width: 100px;
  height: 100px !important;
  padding: 5px;
  box-sizing: border-box;
  flex: 0 0 auto;
}

.product__image-list-item-container:focus {
  outline: none;
}


.product__image-list-item {
  height: 100%;
  width: 100%;
  background-image: url('./assets/product.png');
  background-size: cover;
  background-position: center;
  border-radius: 4px;

}


.product__tabs-section {
  flex: 1 1 50%;
  width: 50%;
  padding: 0 20px;
  background-color: white;
}

@media screen and (max-width: 809px) {
  .product__tabs-section {
    height: unset;
    flex: 1 1 auto;
    width: unset;
  }
}


.product__tab-bar {
  border-bottom: 2px solid #dee2e6;
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
}


.product__tab {
  font-size: 16px;
  border-bottom: 3px solid transparent;
  cursor: pointer;
}

.product__tab--active {
  border-bottom: 3px solid #7165e5;
}


.product__tab-scrolling-content {
  height: 250px;
  padding-right: 10px;
  position: relative;
}

@media screen and (max-width: 809px) {
  .product__tab-scrolling-content {
    height: unset;
    overflow-y: visible;
  }
}


.product__overview--hidden {
  display: none;
}


.product__overview-top-row {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 10px;
}

@media screen and (max-width: 900px) {
  .product__overview-top-row {
    flex-direction: column;
  }
}

@media screen and (max-width: 809px) {
  .product__overview-top-row {
    flex-direction: row;
  }
}

@media screen and (max-width: 400px) {
  .product__overview-top-row {
    flex-direction: column;
  }
}


.product__category-title {
  margin: 0;
  font-size: 12px;
  color: #7165e5;
}


.product__eye-icon {
  margin-right: 10px;
}


.product__watching {
  font-size: 12px;
  margin: 0;
  display: flex;
  align-items: center;
}


.product__title {
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 400;
}


.product__description {
  font-size: 1rem;
  line-height: 1.5rem;
}


.product__stats-list {
  border-bottom: 2px solid #dee2e6;
  display: flex;
  justify-content: space-between;
}


.product__stat-list-item {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  flex: 1 1 auto;
  padding-bottom: 10px;
  justify-content: flex-start;
  margin-top: 10px;
  position: relative;
}

.product__stat-list-item+.product__stat-list-item:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 70%;
  border-right: 2px solid #dee2e6;
}


.product__stat-list-item-number {
  margin: 0;
  color: #7165e5;
  font-size: 30px;
}


.product__stat-list-item-title {
  margin: 0;
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 10px;
}


.product__tabs-bottom-row {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}


.product__messages--hidden {
  display: none;
}


.product__messages-top-row {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1030px) {
  .product__messages-top-row {
    flex-direction: column;
    justify-content: center;
  }
}

@media screen and (max-width: 809px) {
  .product__messages-top-row {
    flex-direction: row;
    justify-content: space-between;
  }
}

@media screen and (max-width: 500px) {
  .product__messages-top-row {
    flex-direction: column;
    justify-content: center;
  }
}


.product__messages-sorting {
  display: flex;
  margin: 4px 0;
}

@media screen and (max-width: 1150px) {
  .product__messages-sorting {
    text-align: center;
  }
}


.product__messages-sorting-item {
  font-size: 12px;
  margin: 0;
  cursor: pointer;
}

.product__messages-sorting-item--active {
  color: #7165e5;
}




.product-messages-sorting-line {
  height: 12px;
  border-right: 2px solid #dee2e6;
  margin: 0 5px;
}


.product__message {
  margin-bottom: 16px;
}

.product__message+.product__message {
  border-top: 2px solid #dee2e6;
}


.product__message-top-row {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}


.product__message-date {
  font-size: 0.8rem;
  opacity: 0.5;
  margin: 0;
}


.product__message-seller-replied {
  color: #7165e5;
  font-size: 0.8rem;
  margin: 0;
}


.product__message-username {}


.product__message-text {
  line-height: 1.5rem;
}


.product__message-icons-list {
  display: flex;
}


.product__message-icons-list-item {
  display: flex;
  margin-right: 20px;
}


.product__message-icons-list-item-icon {
  height: 16px;
  margin-right: 8px;
}


.product__message-icons-list-item-number {
  font-size: 16px;
  opacity: 0.5;
  margin: 0;
}


/* -------------------------------------------------------------------------- *\
  Product Page - Spots
\* -------------------------------------------------------------------------- */
.product__spots-section {
  width: 100%;
  background-color: #f5f5f5;
  margin-bottom: -155px;
  padding-top: 50px;
  padding-bottom: 100px;
}

@media screen and (min-width: 768px) {
  .product__spots-section {
    margin-top: -70px;
    padding-top: 120px;
  }
}


.product__spots-list {
  margin: 0 auto;
  width: 300px;
  margin-bottom: 50px;
  margin-top: 50px;
}

@media screen and (min-width: 640px) {
  .product__spots-list {
    width: 620px;
  }
}

@media screen and (min-width: 1130px) {
  .product__spots-list {
    width: 930px;
  }
}

.product__spots-list-page {
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  display: flex;
}


.product__spot {
  width: 300px;
  height: 50px;
  box-sizing: border-box;
  padding: 0 8px;
  margin: 0 5px 10px;
  background-color: rgba(113, 101, 229, 0.2);
  border: 2px solid #7165e5;
  border-radius: 3px;
  align-items: center;
  display: flex;
  color: #7165e5;
  justify-content: space-between;
  transition: box-shadow 0.3s;
  box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0);
  position: relative;
}

.product__spot::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 3px;
  opacity: 0;
  background-color: #4a3dc9;
  transition: opacity 0.3s;
}


.product__spot--open {
  background-color: #7165e5;
  border: none;
  padding: 0 10px;
  color: white;
  cursor: pointer;
}


.product__spot--inner {
  width: 280px;
}

@media screen and (min-width: 640px) {
  .product__spot--inner {
    width: 290px;
  }
}

@media screen and (min-width: 1130px) {
  .product__spot--inner {
    width: 295px;
  }
}


.product__spot--selected {
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
  position: relative;
}

.product__spot--selected::before {
  opacity: 1;
}

.product__spot-line {
  height: 100%;
  border-right: 2px solid rgba(113, 101, 229, 0.3);
}

.product__spot--open .product__spot-line {
  border-right: 2px solid rgba(255,255,255, 0.3);
}

.product__spot--mini::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 3px;
  opacity: 0;
  background-color: #4a3dc9;
  transition: opacity 0.3s;
}

.product__spot-number {
  width: 40px;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 0;
}

.product__spot-name {
  margin-left: 10px;
  z-index: 2;
  position: relative;
  margin-bottom: 0;
}


.product__spot-price {
  text-align: right;
  flex: 1 1 auto;
  z-index: 2;
  position: relative;
  margin-bottom: 0;
}


.product__spot-left-content {
  display: flex;
  align-items: center;
  height: 100%;
  z-index: 2;
  position: relative;
}


.product__spot-mini-button:focus {
  box-shadow: unset;
}

.product__spot-spacer {
  width: 300px;
  height: 50px;
  box-sizing: border-box;
  padding: 0 8px;
  margin-bottom: 10px;
}

.product__buy-razz {
  width: 300px;
  background-color: rgba(113, 101, 229, 0.2);
  margin: 0 auto;
  padding: 10px;
  border-radius: 3px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (min-width: 640px) {
  .product__buy-razz {
    width: 610px;
  }
}

@media screen and (min-width: 1130px) {
  .product__buy-razz {
    width: 920px;
  }
}

.product__buy-razz p {
  font-size: 0.8rem;
  line-height: 1.2rem;
  margin: 0;
  margin-right: 10px;
}

@media screen and (min-width: 640px) {
  .product__buy-razz p {
    margin-right: 10px;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}


.product__mini-panel {
  width: 100%;
  background-color: white;
  margin-bottom: 10px;
  border-radius: 3px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  justify-content: space-between;
}

.product__mini-panel--hidden {
  display: none;
}

/* @media screen and (min-width: 640px) {
  .product__mini-panel--1col {
    display: none;
  } }

@media screen and (min-width: 810px) and (max-width: 640px) {
  .product__mini-panel--2col {
    display: none;
  } }

@media screen and (max-width: 810px) {
  .product__mini-panel--3col {
    display: none;
  } } */

.product__mini-top-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 18px;
  margin-top: 10px;
}


.product__mini-title {
  font-size: 1rem;
  margin: 0;
}

.product__mini-title span {
  color: #7165e5;
}


.product__buy-selected-button {
  position: fixed;
  bottom: 30px;
  right: 0;
  z-index: 3;
  border-radius: 100px;
  transform: translate(-30px);
  transition: all 0.3s;
  box-shadow: 0 2px 15px -3px rgba(0, 0, 0, 0.8);
  background-color: #7165e5;
  backface-visibility: hidden;
}

@media screen and (max-width: 767px) {
  .product__buy-selected-button {
    font-size: 1rem;
    padding: 20px 30px;
    bottom: 10px;
    transform: translate(-10px);
    border: 2px solid white;
  }
}

@media screen and (min-width: 768px) {
  .product__buy-selected-button {
    font-size: 1rem;
    padding: 20px 30px;
    bottom: 30px;
    transform: translate(-30px);
    border: 2px solid white;
  }
}

@media screen and (min-width: 992px) {
  .product__buy-selected-button {
    font-size: 1.2rem;
    padding: 25px 40px;
    bottom: 30px;
    transform: translate(-30px);
    border: 3px solid white;
  }
}

.product__buy-selected-button--active {
  transform: translate(110%);
}

/* -------------------------------------------------------------------------- *\
  Customers Page - Header
\* -------------------------------------------------------------------------- */
.customers__header {
  height: 400px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 450px; }

.customers__header-background {
  width: 100%;
  height: 100%;
  position: absolute; }

.customers__header-image {
  width: 100%;
  height: 100%;
  position: absolute; }

.customers__header-gradient {
  height: 100%;
  width: 100%;
  background: linear-gradient(#7A47C2, rgba(122, 71, 194, 0.95));
  position: absolute; }

.customers__header-content {
  position: absolute;
  padding-left: 80px;
  width: 600px; }

@media screen and (max-width: 670px) {
  .customers__header-content {
    padding-left: 10px;
    width: unset;
    padding-right: 10px;
  } }


.customers__header-title {
  color: #f5ca67;
  font-weight: 400;
  font-size: 35px;
  margin-bottom: 50px; }

.customers__header-description {
  color: white;
  line-height: 24px;
  font-size: 16px; }

.customers__header-devices {
  display: none; }

@media screen and (min-width: 1046px) {
  .customers__header-devices {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    width: 350px; } }

@media screen and (min-width: 1370px) {
  .customers__header-devices {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    width: unset; } }

.customers__header-benefits {
  position: relative;
  top: 100%;
  left: 50%;
  transform: translate(-50%);
  margin: 0; }

@media screen and (min-width: 1370px) {
  .customers__header-benefits {
    position: absolute;
    margin-left: 80px;
    margin-top: 50px;
    left: unset;
    transform: unset; } }

.customers__header-benefit-image {
  height: 60px;
  width: 60px;
  background-color: #fafafa;
  border: 1px solid #007bff;
  border-radius: 5px; }

/* -------------------------------------------------------------------------- *\
  Customers Page - Large Icon Section
\* -------------------------------------------------------------------------- */
.customers__main {
  text-align: center; }

.customers__main-line {
  height: 300px;
  margin: 0 50px; }

@media screen and (min-width: 1046px) {
  .customers__main-line {
    margin: 0 100px; } }

.customers__main-icon {
  width: 250px; }

@media screen and (min-width: 1046px) {
  .customers__main-icon {
    width: 330px; } }

.customers__main-icon-image {
  width: 100%; }

.faq__search {
  margin: 100px 50px 70px; }

@media screen and (max-width: 650px) {
  .faq__search {  
    margin: 70px 10px 30px; } }

.faq__search-box {
  background-color: #7165e5;
  border-radius: 3px;
  display: flex;
  padding-top: 3px; }

.faq__search-icon {
  cursor: pointer;
  flex: 0 0 auto; }

.faq__search-input {
  background-color: transparent;
  flex: 1 1 auto;
  border: none;
  outline: unset;
  color: white; }

@media screen and (max-width: 650px) {
  .faq__search-input {
    font-size: 0.8rem;
    font-weight: 600;
  } }

.faq__search-input::placeholder {
  color: rgba(255, 255, 255, 0.8); }

@media screen and (max-width: 650px) {
  .faq__category-list {
    flex-wrap: wrap;
    justify-content: center;
  } }

.faq__category {
  margin: 0 20px; }

.faq__category-icon {
  margin-bottom: 30px;
  width: 100%; }

@media screen and (min-width: 1046px) {
  .faq__category-icon {
    margin: 0 10px;
    margin-bottom: 30px; } }

.faq__category-title {
  color: #7165e5;
  font-weight: 400;
  font-size: 18px;
  text-align: center; }

.faq-cat__search {
  padding: 100px 50px 50px;
  position: relative; }

  @media screen and (max-width: 650px) {
    .faq-cat__search {
      padding: 70px 10px 30px; }
  }

.faq-cat__search-background {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0; }

.faq-cat__search-background-gradient {
  height: 100%;
  width: 100%;
  background-color: rgba(122, 71, 194, 0.94);
  position: absolute; }

.faq-cat__search-background-image {
  height: 100%;
  width: 100%;
  position: absolute; }

.faq-cat__search-box {
  background-color: white;
  border-radius: 3px;
  display: flex;
  padding-top: 3px;
  position: relative; }

.faq-cat__search-icon {
  cursor: pointer;
  flex: 0 0 auto; }

.faq-cat__search-input {
  background-color: transparent;
  flex: 1 1 auto;
  border: none;
  outline: unset;
  color: black; }
  .faq-cat__search-input::placeholder {
    color: rgba(173, 181, 189, 0.8); }

@media screen and (max-width: 650px) {
  .faq-cat__search-input {
    font-size: 0.8rem;
    font-weight: 600;
  } }

@media screen and (max-width: 650px) {
  section.faq-cat__breadcrumbs {
    margin: 20px 0 !important;
  } }

.faq-cat__breadcrumb {
  display: inline;
  margin-right: 15px;
  color: #adb5bd;
  cursor: pointer; }

.faq-cat__breadcrumb-arrow {
  margin-right: 15px; }

@media screen and (max-width: 650px) {
  section.faq-cat__questions {
    margin: 0 !important;
    padding: 0 10px;
  } }

.blog-post__header {
  height: 500px;
  width: 100%;
  clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 0% 100%);
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  margin-bottom: 80px; }

@media screen and (min-width: 1046px) {
  .blog-post__header-content {
    width: 900px;
    margin: 0 auto; } }

.blog-post__header-date {
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  margin-bottom: 24px; }

.blog-post__header-title {
  font-size: 45px;
  font-weight: 400;
  margin: 0; }

/* -------------------------------------------------------------------------- *\
  Categories Page - Header
\* -------------------------------------------------------------------------- */
.categories__header {
  height: 230px;
  position: relative;
  padding-top: 80px;
  padding-left: 100px;
  overflow: hidden; }

.categories__header-background {
  width: 300%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform-origin: 0% 0%;
  transform: rotate(-2.5deg) translateX(-50%);
  overflow: hidden; }

.categories__header-gradient {
  height: 100%;
  width: 100%;
  transform: rotate(2.5deg);
  position: absolute;
  background: linear-gradient(#7A47C2, rgba(122, 71, 194, 0.94)); }

.categories__header-image {
  height: 200%;
  width: 200%;
  transform: rotate(2.5deg);
  position: absolute; }

/* -------------------------------------------------------------------------- *\
  Categories Page - Single Category Section
\* -------------------------------------------------------------------------- */
.categories__category {
  width: 100%;
  display: flex;
  padding: 30px;
  margin: 0 auto;
  align-items: center; }

@media screen and (min-width: 1046px) {
  .categories__category {
    width: 900px; } }

@media screen and (max-width: 650px) {
  .categories__category {
    flex-direction: column;
    padding: 10px; } }

.categories__category-info {
  width: 230px;
  flex: 0 0 auto; }

.categories__category-title {
  font-size: 40px;
  margin: 0;
  margin-bottom: 25px; }

.categories__category-description {
  font-size: 0.8rem;
  line-height: 1.3rem;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 100; }

.categories__category-sub-cats {
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.categories__category-sub-cat {
  width: 180px;
  height: 180px;
  margin: 5px;
  background: #adb5bd;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 20px; }

@media screen and (max-width: 665px) {
  .categories__category-sub-cat {
    width: 140px;
    height: 140px;
    } }

.categories__category-sub-cat-title {
  margin: 0;
  color: white;
  text-decoration: none; }

/* -------------------------------------------------------------------------- *\
  Categories Page - Pre-Footer
\* -------------------------------------------------------------------------- */
.categories__pre-footer {
  position: relative;
  padding-bottom: 50px;
  z-index: 2;
  margin-top: 140px; }

.categories__pre-footer .discount {
  transform: translateY(-40px);
}

.categories__pre-footer-background {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  top: 0; }

.categories__pre-footer-gradient {
  height: 100%;
  width: 100%;
  position: absolute;
  background: linear-gradient(rgba(122, 71, 194, 0.94), #7A47C2); }

.categories__pre-footer-image {
  height: 100%;
  width: 100%;
  position: absolute; }

/* -------------------------------------------------------------------------- *\
  Aside Nav Component
\* -------------------------------------------------------------------------- */

.aside-nav {
  z-index: 5;
  width: 0; }

@media screen and (min-width: 810px) {
  .aside-nav {
    width: 70px;
    border-right: 2px solid #dee2e6; } }

@media screen and (min-width: 1210px) {
  .aside-nav {
    width: 250px;
    border-right: 2px solid #dee2e6; } }

.nav__tablet-bottom-button {
  height: 70px;
  width: 70px;
  border: 1px solid #dee2e6;
  border-top: none; }

.nav__panel {
  display: none;
  position: absolute;
  background-color: white;
  height: 100%;
  width: 250px;
  padding-top: 30px;
  transform: translateX(-100%);
  transition: transform 0.3s;
  top: 0; }

@media screen and (min-width: 810px) {
  .nav__panel {
    display: block; } }

@media screen and (min-width: 1210px) {
  .nav__panel {
    transform: translateX(0);
    background-color: transparent; } }
  
@media screen and (max-height: 1000px) {
  .nav__panel {
    padding-top: 15px;
  }
}

.nav--isOpen .nav__panel {
  transform: translateX(0);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }

.nav__close {
  height: 45px;
  width: 45px;
  padding: 12px;
  display: block;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: 30px;
  cursor: pointer;
  position: absolute;
  right: -20px;
  top: 10px;
}

@media screen and (min-width: 1210px) {
  .nav__close {
    display: none; } }

.nav__logo {
  margin-bottom: 40px;
  margin-left: 30px; }

@media screen and (max-height: 900px) {
  .nav__logo {
    margin-bottom: 20px; }
}

.nav__search {
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 3px;
  margin: 0 30px;
  margin-bottom: 10px; }

.nav__search-input {
  border: none;
  outline: none;
  margin-left: 10px;
  width: 150px; }
  .nav__search-input::placeholder {
    color: #dee2e6;
    opacity: 1; }
  .nav__search-input:-ms-input-placeholder {
    color: #dee2e6; }
  .nav__search-input::-ms-input-placeholder {
    color: #dee2e6; }

.nav__nav {
  margin-left: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-60%);
}

@media screen and (max-height: 1000px) {
  .nav__nav {
    transform: translateY(-68%); } }

@media screen and (max-height: 770px) {
  .nav__nav {
    transform: translateY(-62%); } }

.nav__outer-list {
  margin: 0;
  padding: 0;
  font-size: 16px; }

.nav__outer-list-item {
  display: block;
  margin-bottom: 25px; }

@media screen and (max-height: 1000px) {
  .nav__outer-list-item {
    margin-bottom: 15px; } }

@media screen and (max-height: 900px) {
  .nav__outer-list-item {
    margin-bottom: 10px;
  }
}

.nav__nav-link {
  text-decoration: none;
  color: black; }
  .nav__nav-link:hover {
    text-decoration: none; }

.nav__nav-link--active {
  border-bottom: 3px solid #7165e5;
  padding-bottom: 3px; }

.nav__inner-list {
  margin: 0;
  padding: 0;
  margin-left: 15px;
  font-size: 14px; }

.nav__inner-list-item {
  display: block;
  margin: 15px 0;
  }

@media screen and (max-height: 1000px) {
  .nav__inner-list-item {
    margin: 10px 0;
  }
}

.nav__bottom-content {
  position: absolute;
  bottom: 0;
  width: 100%; }

.nav__mobile-panel {
  width: 100vw;
  height: 100vh;
  background-color: white;
  transform: translateX(0%);
  transition: transform 0.3s; }

.nav--isOpen .nav__mobile-panel {
  transform: translateX(-100%); }

.nav__mobile-panel-side {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 70px;
  transform: translateX(100%);
  transition: transform 0.3s;
  background: white;
  z-index: 5; }

.nav--isOpen .nav__mobile-panel-side {
  transform: translateX(0); }

.nav__mobile-panel-side-scroller {
  overflow-y: scroll;
  height: 100%; }

.nav__mobile-icon {
  height: 20px;
  width: 20px; }

.nav__outer-list--mobile {
  margin-top: 0 !important;
}

.nav__outer-list--mobile .nav__outer-list-item {
  margin-bottom: 20px;
  font-size: 28px;
}

.nav__outer-list--mobile .nav__inner-list-item {
  margin: 17px 0;
  font-size: 22px; }

.nav__mobile-list-container {
  height: 100%;
  margin-top: 150px;
  position: relative;
  padding-right: 20px;
}

.nav__mobile-list-container .ps__rail-y {
  opacity: .5;
}

.nav__sign-in {
  background-color: #7165e5;
  padding: 20px;
}

.nav__sign-in-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.nav__username {
  font-size: 1.2rem;
  margin: 0;
  color: white;
}

.nav__menu-dots {
  cursor: pointer;
}

.nav__sign-in-link {
  color: white;
  margin-bottom: 0.5rem;
}

.nav__notification-circle {
  margin-left: 1.5rem;
  background-color: #f5ca67;
  height: 1.5rem;
  display: inline-flex;
  border-radius: 1.5rem;
  padding: 0 0.4rem;
  justify-content: center;
  color: #7165e5;
}

@media screen and (max-height: 770px) {
  .nav__desktop-social-links {
    display: none;
  } }


/* -------------------------------------------------------------------------- *\
  Account Pages
\* -------------------------------------------------------------------------- */

@media screen and (max-width: 500px) { 
  .account__top-row {
    flex-direction: column !important;
  } }

@media screen and (max-width: 500px) { 
  .account__nav {
    flex-direction: column !important;
  }

  .account__nav p {
    margin: 0;
  }

  .account__nav a {
    margin-bottom: 10px;
  } }

@media screen and (max-width: 500px) { 
  .account__sub-nav {
    flex-direction: column;
  }

  .account__sub-nav a {
    margin-left: 0 !important;
    padding-bottom: 0 !important;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 630px) { 
  .account__sorting {
    display: flex;
    flex-direction: column;
  }

  .account__sorting p {
    margin-left: 0 !important;
  }
}

.account__header-item--isSelected {
  color: #7165e5; }

.account__info-box {
  background-color: rgba(113, 101, 229, 0.1);
  display: flex;
  color: #7165e5;
  padding: 5px 50px;
  line-height: 25px;
  font-size: 16px; }

.info-box__icon {
  margin-right: 40px; }

.account__right-align {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 50px; }

.create__row-item {
  display: flex;
  align-items: center; }

.create__terms {
  color: #7165e5;
  font-size: 14px;
  margin-left: 15px; }

.account__link--active .account__header-item {
  color: #7165e5; }

.account__row-item {
  display: flex;
  align-items: center; }

.account__row-item .create__select-container {
  width: 200px; }

.create__select-title {
  margin: 0;
  margin-right: 20px; }

.account__row-item .create__select-container::after {
  top: 20px; }

@media screen and (max-width: 700px) {
  .account__actions {
    flex-direction: column;
    align-items: center;
  }

  .account__actions > * {
    margin-bottom: 10px;
    width: 100%;
    max-width: unset !important;
  }
}

@media screen and (max-width: 700px) {
  .account__notification {
    flex-direction: column;
  }

  .account__notification > * {
    align-items: stretch;
    max-width: unset !important;
  }

  .account__notification .col {
    flex: 0 0 auto;
  }

  .notification-circle {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .account__order-details-top {
    flex-direction: column;
  }

  .account__order-details-top > * {
    flex: 1 1 auto !important;
    max-width: unset !important;
  }
}

@media screen and (max-width: 700px) {
  .account__order-summary {
    flex-direction: column;
  }

  .account__order-summary > * {
    flex: 1 1 auto !important;
    margin-bottom: 1.5rem;
  }
}

.account__report-issue {
  margin-bottom: 80px;
}

@media screen and (max-width: 700px) {
  .account__report-issue-content {
    flex-direction: column;
  }

  .account__report-issue-content textarea {
    min-height: 150px;
  }

  .account__report-issue-content > * {
    flex: 1 0 auto !important;
  }

  .account__report-issue-content div:last-child {
    margin-top: 10px;
    margin-left: 0 !important;
  }
}

.account__card-list {
  margin-bottom: 80px;
}

.account__card--isOpen .account__card-bottom-toggle {
  margin-bottom: 10px;
}

.account__card {
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  border-radius: 5px;
  margin-bottom: 20px;
  position: relative; }

.account__card--my-razz .account__card-boost {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 20px;
  left: 0;
  background-color: #7165e5;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center; }

.account__card--my-razz .account__card-bottom-left {
  flex-wrap: wrap;
  width: 450px;
  justify-content: space-between; }

.account__card--my-razz .account__card-bottom-left .account__card-bottom-item + .account__card-bottom-item {
  margin-left: 0; }

.account__card--my-razz .account__card-bottom-item {
  width: 220px; }

@media screen and (max-width: 800px) {
  .account__card--my-razz .account__card-bottom-item {
    width: unset; } }

.account__card--my-razz .account__card-bottom-item--right {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }

.account__card-top {
  display: flex;
  justify-content: space-between;
  padding: 20px 40px; }


@media screen and (max-width: 800px) { 
  .account__card-top {
    flex-direction: column;
    padding: 10px 10px;
} }

.account__card-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 15px; }

@media screen and (max-width: 800px) {
  .account__card-info {
    margin-left: 5px;
  }

  .account__card-info h2 {
    font-size: 1rem;
  }

  .account__card-info p {
    font-size: 0.8rem;
  }
}

.account__card-info > * {
  margin: 0; }

.account__card-status span {
  color: #7165e5; }

.account__card-image {
  height: 100px;
  width: 100px;
  background-color: #adb5bd;
  border-radius: 5px;
  flex: 0 0 auto; }

@media screen and (max-width: 800px) {
  .account__card-image {
    height: 80px;
    width: 80px;
  } }

.account__top-left {
  display: flex; }

@media screen and (max-width: 800px) {
  .account__top-left {
   margin-bottom: 10px;
  } }

.account__top-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between; }

.account__top-right > * {
  margin: 0; }

@media screen and (max-width: 800px) { 
  .account__top-right button:first-child {
    margin-bottom: 10px;
} }

.account__card-title {
  font-weight: 400; }

.account__card-bottom {
  background-color: #f5f5f5;
  padding: 20px 40px; }

.account__card--my-razz .account__card-bottom-content {
  display: none; }

.account__card--isOpen .account__card-bottom-content {
  display: flex; }

.account__card-bottom-content {
  display: flex;
  justify-content: space-between; }

@media screen and (max-width: 800px) {
  .account__card-bottom-content {
    flex-direction: column; } }

.account__card-bottom-left {
  display: flex; }

@media screen and (max-width: 800px) { 
  .account__card-bottom-left {
    flex-direction: column; } }

.account__card-bottom-left .account__card-bottom-item + .account__card-bottom-item {
  margin-left: 70px; }

@media screen and (max-width: 800px) {
  .account__card-bottom-left .account__card-bottom-item + .account__card-bottom-item {
    margin-left: 0; } }

@media screen and (max-width: 800px) {
  .account__card-bottom-item {
    margin-bottom: 1rem;
  } }

.account__card-bottom-item-title {
  font-size: 12px;
  text-transform: uppercase;
}

@media screen and (max-width: 800px) {
  .account__card-bottom-item-title {
    margin-bottom: 0.2rem !important; } }

.account__card-bottom-item-text {
  font-size: 16px;
  color: #7165e5;
  margin: 0; }

.account__card-bottom-item-text span {
  color: black;
}

.account__watch-list {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 80px; }

.account__watch-item {
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 200px;
  overflow: hidden;
  margin: 5px; }

.account__watch-item-image {
  background-color: #adb5bd;
  height: 200px;
  width: 100%;
  padding: 15px;
  text-align: right; }

.account__watch-item-title {
  font-size: 14px;
  margin: 15px;
  font-weight: 400; }

.account__watch-item-subtitle {
  font-size: 11px;
  color: #7165e5;
  margin: 15px;
  font-weight: 400; }

.account__subtitle {
  color: #adb5bd; }
  .account__subtitle span {
    color: #7165e5; }

.account__shipping-bar {
  width: 100%;
  height: 30px;
  background-color: #7165e5;
  border-radius: 6px;
  overflow: hidden; }

.account__shipping-bar-item {
  width: 50%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2); }

.create__row-left {
  font-size: 14px; }

.create__row-right {
  text-align: right;
  color: #7165e5;
  font-size: 14px; }

.notification-circle {
  height: 8px;
  width: 8px;
  background-color: #f5ca67;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translateY(-50%); }

.account__balance span {
  color: #7165e5;
}

.account__transactions {
  border: 2px solid #e9ecef;
  border-radius: 5px;
  margin-bottom: 3rem;
}

.account__transactions-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
}

.account__transactions-header > * {
  margin-bottom: 0;
}

.account__transactions-title {
  font-size: 1.3rem;
}

.account__transactions-item {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

@media screen and (max-width: 600px) {
  .account__transactions-item {
    flex-direction: column;
  }
}

.account__transactions-item:nth-child(odd) {
  background-color: #f5f5f5;
}

.account__transactions-item:nth-child(even) {
  background-color: #e9e9e9;
}

.account__transactions-item p {
  margin-bottom: 0;
}

.account__transactions-item-title {
  color: #7165e5;
}

.account__credit-form {
  display: flex;
  flex-direction: column;
}

.account__credit-form-half {
  display: flex;
  align-items: flex-end;
}

@media screen and (max-width: 600px) {
  .account__credit-form-half {
    flex-direction: column;
    align-items: stretch;
  }
}

.account__credit-form-half > * {
  flex: 1 0 auto;
}

.account__credit-form-half > * + * {
  margin-left: 0.5rem;
}

@media screen and (max-width: 600px) {
  .account__credit-form-half > * + * {
    margin-left: 0rem;
  }
}

.account__transfer-form {
  display: flex;
}

.account__transfer-form > * {
  flex: 1 0 auto;
}

.account__transfer-form > * + * {
  margin-left: 0.5rem;
}

@media screen and (max-width: 850px) {
  .account__transfer-form {
    flex-direction: column;
  }

  .account__transfer-form > * + * {
    margin-left: 0rem;
  }
}



/* -------------------------------------------------------------------------- *\
  About Page - Header
\* -------------------------------------------------------------------------- */

@media screen and (max-width: 810px) {
  header.about__header {
    margin-left: 0 !important;
    margin-right: 0 !important;
  } }


.about__header-background {
  height: 500px;
  width: 100%;
  background-position: center;
  background-size: cover;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  align-items: center;
  margin-right: 120px; }

@media screen and (max-width: 810px) {
  .about__header-background {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.about__header-title {
  width: 680px; }

@media screen and (max-width: 810px) {
  h1.about__header-title {
    margin-left: 10px !important; } }

/* -------------------------------------------------------------------------- *\
  About Page - Highlights
\* -------------------------------------------------------------------------- */
@media screen and (max-width: 700px) {
  .about__highlights {
    flex-direction: column; } }

.about__highlight {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 30px; }

@media screen and (max-width: 700px) {
  .about__highlight {
    margin: 0 auto; } }

.about__highlight-image {
  height: 100px;
  width: 130px;
  background-color: rgba(173, 216, 230, 0.3);
  border: 1px solid lightBlue;
  border-radius: 4px;
  margin-bottom: 40px; }

/* -------------------------------------------------------------------------- *\
  About Page - What Section
\* -------------------------------------------------------------------------- */
@media screen and (min-width: 1046px) {
  .about__what {
    width: 900px; } }

.about__what-cards-desktop {
  display: none; }

@media screen and (min-width: 1046px) {
  .about__what-cards-desktop {
    display: flex; } }

.about__what-cards-tablet {
  display: flex; }

@media screen and (min-width: 1046px) {
  .about__what-cards-tablet {
    display: none; } }

.about__what-cards-column {
  display: flex;
  flex-direction: column; }

.about__what-cards-column--middle {
  transform: translateY(50px); }

.about__what-card {
  color: white;
  width: 200px;
  height: 200px;
  background-color: #7165e5;
  border-radius: 6px;
  padding: 20px;
  display: inline-block;
  margin: 8px; }

.about__what-card-image {
  height: 60px;
  width: 60px;
  background-color: rgba(173, 216, 230, 0.1);
  margin-bottom: 10px; }

.about__what-card-title {
  color: #f5ca67;
  font-weight: 400;
  font-size: 15px;
  margin: 0;
  margin-bottom: 10px; }

.about__what-card-description {
  font-size: 13px;
  font-weight: 100;
  line-height: 20px;
  margin: 0; }

.about__what-info {
  margin-left: 20px; }

.about__what-info-title {
  color: #7165e5;
  font-weight: 400;
  font-size: 22px;
  margin-bottom: 30px; }

.about__what-info-description {
  font-weight: 100;
  line-height: 22px;
  font-size: 14px;
  margin-bottom: 30px; }

/* -------------------------------------------------------------------------- *\
  About Page - Blog Section
\* -------------------------------------------------------------------------- */
.about__blog {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px; }

@media screen and (min-width: 1046px) {
  .about__blog {
    width: 900px; } }

.about__blog-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px; }

.about__blog-title {
  font-weight: 400;
  font-size: 32px;
  margin: 0; }

.about__blog-posts {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }

@media screen and (min-width: 1046px) {
  .about__blog-posts {
    justify-content: space-between;
    flex-direction: row; } }

.about__blog-post {
  width: 270px;
  margin: 5px 5px; }

@media screen and (min-width: 1046px) {
  .about__blog-post {
    margin: 5px 5px; } }

.about__blog_post-title {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px; }

.about__blog-post-description {
  font-weight: 100;
  line-height: 28px;
  font-size: 16px;
  color: #adb5bd; }

.about__blog-post-date {
  color: #7165e5;
  font-weight: 400;
  font-size: 14px; }

.about__blog-post-image {
  height: 160px;
  width: 100%;
  background-color: rgba(173, 216, 230, 0.3);
  border-radius: 6px; }

.card-slider__slide {
  height: 300px;
  padding-top: 10px;
  outline: none; }

@media screen and (min-width: 810px) {
  .card-slider__slide {
    height: 350px;
    padding-top: 10px;
    outline: none; } }

.card-slider__slide-content {
  width: 150px;
  margin: 0 auto; }

@media screen and (min-width: 810px) {
  .card-slider__slide-content {
    width: 200px;
    margin: 0 auto; } }

.card-slider__slide-image {
  height: 200px;
  width: 100%;
  background-color: #adb5bd;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-bottom: 20px;
  transition: box-shadow 0.3s; }
  .card-slider__slide-image:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); }

@media screen and (min-width: 810px) {
  .card-slider__slide-image {
    height: 280px; } }

/* -------------------------------------------------------------------------- *\
  Card Slider Component
\* -------------------------------------------------------------------------- */
.card-slider .slick-dots {
  bottom: -35px; }

.card-slider .slick-dots li {
  margin: 0;
  width: 18px;
  height: 18px; }

.card-slider .slick-dots li button {
  padding: 0;
  width: 18px;
  height: 18px; }

.card-slider .slick-dots li button:before {
  width: 18px;
  height: 18px;
  font-size: 7px;
  transition: all 0.3s;
  opacity: 0.2; }

.card-slider .slick-dots li.slick-active button:before {
  color: #7165e5;
  opacity: 1; }

.card-slider .slick-prev:before {
  content: '';
  height: 10px;
  width: 10px;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(45deg);
  border-left: 3px solid #e9ecef;
  border-bottom: 3px solid #e9ecef; }

.card-slider .slick-next:before {
  content: '';
  height: 10px;
  width: 10px;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  right: 0;
  transform: rotate(-45deg);
  border-right: 3px solid #e9ecef;
  border-bottom: 3px solid #e9ecef; }

.after-arrow::after {
  content: "";
  height: 10px;
  width: 10px;
  border-top: 1px solid #adb5bd;
  border-right: 1px solid #adb5bd;
  position: absolute;
  transform: rotate(45deg);
  right: 0px; }

/* -------------------------------------------------------------------------- *\
  Create Pages
\* -------------------------------------------------------------------------- */
@media screen and (max-width: 874px) {
  .create__nav {
    overflow-x: scroll;
  }
}


.create__nav ul {
  min-width: 750px;
}

@media screen and (max-width: 700px) {
  .create__review-bottom-row {
    flex-direction: column;
  }

  .create__review-bottom-row .create__bottom-left {
    margin-bottom: 10px;
  } }

.create__boost-card {
  width: 180px;
  height: 300px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  border: 1px solid #e9ecef;
  justify-content: space-between;
  margin-right: 10px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s; }

@media screen and (max-width: 600px) {
  .create__boost-card {
    width: 80px;
    height: unset;
  } }

.create__boost-card--isSelected {
  transform: scale(1.1);
  margin-right: 19px;
  margin-left: 9px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); }

.create__boost-icon {
  width: 100px; }

@media screen and (max-width: 600px) {
  .create__boost-icon {
    width: 50px; } }

.boost__cards {
  width: 100%;
  justify-content: center;
  margin-bottom: 50px;
  }

@media screen and (min-width: 1046px) {
  .boost__cards {
    width: unset;
    margin-bottom: 0; } }

.main-top-spacer {
  margin-top: 50px;
}

.main-top-spacer hr:last-child {
  margin-bottom: 80px;
}

.create__image-drop-field {
  height: 250px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #e9ecef;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 50px;
  justify-content: space-between;
  margin-bottom: 10px; }

.create__image-drop-subtitle {
  font-size: 12px;
  margin: 0; }

.create__image-drop-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px; }

.create__image-drop-list-item {
  height: 75px;
  width: 75px;
  background-color: #adb5bd;
  border-radius: 3px; }

.create__image-drop-list-item--isSelected {
  background-color: #7165e5; }

.create__image-drop-title {
  font-size: 16px;
  margin: 0; }

.create__circle {
  height: 7px;
  width: 7px;
  background-color: #adb5bd;
  border-radius: 50%; }

.create__circle +
.create__circle {
  margin-left: 10px; }


.create__shipping-cards {
  display: flex; }

.create__shipping-card {
  height: 300px;
  width: 250px;
  border: 1px solid #e9ecef;
  border-radius: 5px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  background-color: white;
  transition: all 0.3s; }

.create__shipping-card--isSelected {
  transform: scale(1.1);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 0 20px; }


.create__review-images {
  height: 218px;
  margin-bottom: 30px;
  display: flex; }

.create__review-images-list {
  width: 170px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.create__review-images-list-item {
  height: 66px;
  width: 80px;
  background-color: #adb5bd;
  margin-bottom: 10px;
  border-radius: 5px; }

.create__review-images-selected {
  flex: 1 1 auto;
  background-color: #adb5bd;
  margin-left: 10px;
  border-radius: 5px; }

.toggle {
  display: flex;
  align-items: center; }

.toggle > label {
  margin: 0;
  display: flex;
  align-items: center; }

.toggle-button {
  flex: 0 0 auto;
  width: 44px;
  height: 22px;
  background-color: #eee;
  position: relative;
  border-radius: 13px;
  transition: background-color 0.2s;
  display: inline-block;
  margin-right: 20px; }
  .toggle-button::before {
    content: "";
    position: absolute;
    height: 14px;
    width: 14px;
    background-color: white;
    left: 4px;
    top: 4px;
    border-radius: 50%;
    transition: all 0.2s; }

.toggle input[type=checkbox] {
  display: none; }

.toggle input[type=checkbox]:checked + .toggle-button {
  background-color: #7165E5; }
  .toggle input[type=checkbox]:checked + .toggle-button::before {
    transform: translateX(22px);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3); }

.toggle__label-link {
  color: #7165e5;
  transform: none; }

.toggle-label {
  display: inline-block;
  position: relative;
  font-size: 0.9rem;
  margin: 0; }


/* -------------------------------------------------------------------------- *\
  Questions (not sure where this is used...)
\* -------------------------------------------------------------------------- */
.questions {
  height: 550px;
  width: 100%;
  position: relative;
  margin-bottom: -75px; }

@media screen and (max-width: 700px) {
  .questions {
    height: unset;
  } }

.questions__background-gradient {
  background: linear-gradient(rgba(122, 71, 194, 0.95), #7A47C2 60%); }

.questions__content {
  position: relative;
  display: flex;
  width: 650px;
  margin: 0 auto;
  padding: 80px 0;
  justify-content: space-between;
  align-items: center; }

@media screen and (min-width: 1046px) {
  .questions__content {
    width: 880px; } }

@media screen and (max-width: 700px) {
  .questions__content {
    width: 100%;
    flex-direction: column;
    padding-left: 10px; 
      padding-right: 10px; } }

.questions__info {
  width: 320px;
  margin-right: 20px; }

@media screen and (max-width: 700px) {
  .questions__info {
    width: unset;
    margin-right: 0; 
    align-self: stretch;
    margin-bottom: 20px;
    } }

.questions__form {
  width: 500px; }

@media screen and (max-width: 700px) {
  .questions__form {
    width: unset; 
    align-self: stretch; } }


/* -------------------------------------------------------------------------- *\
  Sign Up Page
\* -------------------------------------------------------------------------- */
.sign-up {
  text-align: center;
  padding: 100px 0;
  position: relative;
  margin-bottom: -75px;
  overflow: hidden; }

.sign-up__background {
  height: 100%;
  width: 200%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform: rotate(-2.5deg) translateX(-25%);
  overflow: hidden; }

.sign-up__background-gradient {
  height: 100%;
  width: 200%;
  position: absolute;
  background: linear-gradient(rgba(122, 71, 194, 0.95), #7A47C2 60%); }

.sign-up__background-image {
  height: 100%;
  width: 200%;
  position: absolute;
  transform: rotate(2.5deg) translateX(-25%); }

.sign-up__cards {
  display: flex;
  margin: 0 auto;
  margin-bottom: 40px;
  flex-wrap: wrap;
  justify-content: center; }

.sign-up__card {
  width: 430px;
  border-radius: 5px;
  margin: 15px;
  padding: 25px 25px;
  display: flex;
  background: linear-gradient(45deg, #8466E4, #BF65E5);
  box-shadow: 0 5px 25px rgba(73, 26, 118, 0.3); }

.sign-up__card-info {
  text-align: left;
  margin-left: 25px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center; }

/* -------------------------------------------------------------------------- *\
  Product List Component
\* -------------------------------------------------------------------------- */
.product-list {
  display: flex;
  border-top: 2px solid #dee2e6;
  transform: translateY(76px);
  font-size: 14px; }

@media screen and (max-width: 930px) {
  .product-list {
    flex-direction: column-reverse;
  } }

.product-list__results {
  flex: 1 1 auto;
  background-color: #f8f8f8;
  padding-bottom: 75px; }

.product-list__top {
  border-bottom: 1px solid #dee2e6;
  margin: 0 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.product-list__available {
  flex: 0 0 auto;
  margin: 15px 0; }

.product-list__available label {
  margin-bottom: 0; }

.product-list__available-number {
  margin: 0 6px;
  display: inline-block;
  color: #adb5bd;
  cursor: pointer; }

.product-list__available-text {
  display: inline-block;
  color: #adb5bd;
  margin-bottom: 0; }

.product-list__available input[type="radio"] {
  display: none;
  cursor: pointer; }

.product-list__available input[type="radio"]:checked + p {
  color: #adb5bd; }

.product-list__item {
  background-color: white;
  width: 250px;
  border-radius: 8px;
  overflow: hidden;
  margin: 15px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); }

.product-list__item-info {
  padding: 20px; }

.product-list__item-info > h3 {
  white-space: nowrap;
  position: relative;
}

.product-list__item-info > h3:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 60px;
  background: linear-gradient(-90deg, white 33%, rgba(255, 255, 255, 0));
  right: -20px;
}

.product-list__item-thumbnail {
  height: 250px;
  width: 250px;
  background-color: #adb5bd; }

.product-list__pagination {
  margin-bottom: 100px; }

.product-list__pagination-ul {
  padding: 0;
  margin: 0;
  text-align: right;
  margin-right: 40px;
  margin-bottom: 40px; }

.product-list__pagination-li {
  display: inline-block;
  margin-left: 30px; }

.product-list__pagination-li--isSelected {
  background-color: #7165e5;
  height: 30px;
  width: 30px;
  color: white;
  display: flex;
  text-align: center;
  line-height: 30px; }

.product-list__options {
  flex: 0 0 auto;
  width: 280px;
  padding: 20px; }

@media screen and (min-width: 1046px) {
  .product-list__options {
    width: 300px; } }

.product-list__options-look {
  margin-bottom: 35px; }

.product-list__options-look-image {
  margin-right: 20px; }

.Category_list_options h2 {
  font-weight: 400;
  color: #7165e5;
  font-size: 22px;
  margin: 0; }

.product-list__options-groups {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 30px; }

.product-list__options-group {
  display: block;
  margin: 30px 0; }

.product-list__options-group p {
  display: inline-block;
  margin: 0;
  color: rgba(113, 101, 229, 0.5);
  margin-left: 5px; }

.product-list__options-group input {
  display: none; }

.product-list__options-radio {
  display: inline-block;
  height: 14px;
  width: 14px;
  background-color: white;
  position: relative;
  border-radius: 50%;
  margin-right: 10px;
  border: 1px solid #7165e5; }
  .product-list__options-radio::before {
    content: "";
    position: absolute;
    height: 6px;
    width: 6px;
    background-color: #7165e5;
    top: 3px;
    left: 3px;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.2s; }

.product-list__options-group input[type=radio]:checked +
.product-list__options-radio::before {
  opacity: 1; }

.product-list__options-toggles-sort {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px; }

.product-list__options-toggles-sort-select {
  display: flex; }

.product-list__options-toggles-sort-select-drop-down {
  border: none;
  background-color: transparent;
  appearance: none;
  outline: none; }
  .product-list__options-toggles-sort-select-drop-down::-ms-expand {
    display: none; }

.product-list__options-toggles-sort-arrow {
  height: 8px;
  width: 8px;
  border-bottom: 2px solid #7165e5;
  border-right: 2px solid #7165e5;
  transform: rotate(45deg);
  margin-left: 10px; }

.product-list__options-toggles-sort-sort-by {
  font-weight: 400;
  font-size: 16px; }

.product-list__options-toggles-sort-clear {
  font-weight: 100;
  font-size: 12px;
  color: #ccc; }

.product-list__options-toggles ul {
  margin: 0;
  padding: 0; }

.product-list__options-toggles li {
  display: block; }

.remove-space {
  margin-top: -75px; }


/* -------------------------------------------------------------------------- *\
  Footer Component
\* -------------------------------------------------------------------------- */
.footer__background {
  height: 200%;
  width: 200%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  transform-origin: 0% 50%;
  transform: rotate(-2.5deg) translate(-50px, 80px); }

.footer__diagonal-line {
  background: linear-gradient(90deg, #8636BC, #7A47C2);
  transform: rotate(-2.5deg) translate(-50px, 75px);
  transform-origin: 0% 50%;
  height: 6px;
  width: 150%;
  position: absolute;
  top: 0;
  left: 0; }

.footer__content {
  width: 100%; }

@media screen and (min-width: 1046px) {
  .footer__content {
    width: 900px; } }

/* -------------------------------------------------------------------------- *\
  Footer Component - Top Section
\* -------------------------------------------------------------------------- */
.footer__top {
  display: flex;
  align-items: center;
  border-bottom: none;
  padding-bottom: 30px;
  margin-top: 50px;
  margin-bottom: 10px; }

@media screen and (min-width: 810px) {
  .footer__top {
    border-bottom: 2px solid rgba(122, 71, 194, 0.7);
    margin-top: 75px;
    margin-bottom: 50px; } }

.footer__top-item {
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  align-items: center; }

@media screen and (min-width: 810px) {
  .footer__top-item {
    flex: 1 1 50%;
    display: flex;
    flex-direction: row; } }

@media screen and (min-width: 1046px) {
  .footer__top-item-text {
    font-size: 14px; } }

.footer__top-item-icon {
  margin-right: 0;
  margin-bottom: 15px; }

@media screen and (min-width: 810px) {
  .footer__top-item-icon {
    margin-right: 30px;
    margin-bottom: 0; } }

.footer__top-line {
  width: 0;
  height: 80px;
  border: 1px solid rgba(122, 71, 194, 0.7);
  margin: 0 60px;
  border-radius: 10px; }

/* -------------------------------------------------------------------------- *\
  Footer Component - Bottom Section
\* -------------------------------------------------------------------------- */
.footer__bottom {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px; }

.footer__bottom-column {
  flex: 0 0 auto; }

.footer__bottom-column--no-title {
  margin-top: 50px; }

.footer__social-media-icon {
  margin-right: 15px;
  cursor: pointer; }

.footer__bottom-column-title {
  margin: 0;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 400; }

.footer__bottom-column-li {
  font-size: 13px; }

@media screen and (min-width: 1046px) {
  .footer__bottom-column-li {
    font-size: 15px; } }

.footer__logo {
  margin-bottom: 30px;
  width: 100px; }

@media screen and (min-width: 1046px) {
  .footer__logo {
    margin-bottom: 30px;
    width: unset; } }

.footer__copy {
  margin: 0;
  color: rgba(255, 255, 255, 0.3);
  font-size: 14px;
  text-align: center; }

@media screen and (min-width: 810px) {
  .footer__copy {
    text-align: left; } }

.footer__landing-content {
  margin: 5rem auto 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 900px;
}

@media screen and (max-width: 570px) {
  .footer__landing-content {
    flex-direction: column;
  }
}

.footer__landing-social-media {
  margin-right: 2rem;
  flex: 0 0 auto;
}

.footer__landing-social-media > img + img {
  margin-left: 1rem;
}

@media screen and (max-width: 570px) {
  .footer__landing-social-media {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}



/* -------------------------------------------------------------------------- *\
  Discount Component
\* -------------------------------------------------------------------------- */
.discount {
  background-color: white;
  width: unset;
  border-radius: 12px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 15px 20px;
  margin: 0 15px; }

@media screen and (min-width: 810px) {
  .discount {
    background-color: white;
    width: 700px;
    border-radius: 12px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 35px 60px;
    margin: 0 auto; } }